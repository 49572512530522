import { Box, Typography } from '@mui/material';

import { ORIGINAL_PROJECT_ID, SENTIMENTS_PROJECT_ID, SNOWFLAKE_PROJECT_ID } from 'config';

function MintTitleSection({
  image,
  projectId,
  projectName,
  projectDescription,
  snowflakePage,
}: {
  image: any;
  projectId: string;
  projectName: string;
  projectDescription: string;
  snowflakePage?: boolean;
}) {
  function demoHtml(projectId: string) {
    switch (projectId) {
      case ORIGINAL_PROJECT_ID:
        return 'original.html';
      case SNOWFLAKE_PROJECT_ID:
        return 'snowflakes.html';
      case SENTIMENTS_PROJECT_ID:
        return 'sentiments.html';
    }
  }
  return (
    <Box sx={{ display: 'flex', width: '90%' }}>
      <Box marginBottom={'41px'}>
        <div style={{ width: '585px', height: '585px', marginRight: '41px' }}>
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            <iframe
              src={process.env.PUBLIC_URL + `/static/${demoHtml(projectId)}`}
              style={{ position: 'absolute', width: '100%', height: '100%', border: 'none' }}
              scrolling="no"
            />
          </div>
        </div>
      </Box>
      <Box sx={{ alignSelf: 'flex-end', paddingBottom: '60px', marginBottom: '50px' }}>
        <Typography
          sx={{
            fontSize: '24px',
            fontWeight: 'bold',
            marginBottom: '10px',
            fontFamily: 'Metropolis',
            textDecorator: 'uppercase',
            color: snowflakePage ? 'black' : 'white',
          }}
        >
          {projectName}
        </Typography>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 'bold',
            marginBottom: '20px',
            fontFamily: 'Metropolis',
            textDecorator: 'uppercase',
            color: snowflakePage ? 'black' : 'white',
          }}
        >
          {projectId === ORIGINAL_PROJECT_ID ? ("Click through the demo on the left"):("Click on the demo to the left.")}
        </Typography>
        <Typography
          sx={{
            fontFamily: 'Inter',
            color: snowflakePage ? 'rgba(0,0,0,0.75)' : 'rgba(255,255,255,0.75)',
            fontSize: '16px',
          }}
        >
          {projectId === ORIGINAL_PROJECT_ID ? ("This is our first greeting card collection, an exploration of a Perlin noise flow field. On the left you can see an example of a card minted by one of our co-founders. When you press purchase a new unique design is generated for your card. See below for some of the Originals pieces made so far."):(
            projectId === SNOWFLAKE_PROJECT_ID ? ("This is a collection of unique algorithmically generated snow flakes. They’re made kind of like the ones you cut out of paper as a kid. When you press purchase a new snowflake is created for your card. See below for some of the Snowflakes made so far."):(
              projectId === SENTIMENTS_PROJECT_ID ? ("This is an AI collection that will paint an art piece based on the message that you put inside of it. See below for some of the Mosaics made so far."):("")))}
        </Typography>
        <br></br>
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontSize: '16px',
            color: snowflakePage ? 'rgba(0,0,0,0.75)' : 'rgba(255,255,255,0.75)',
          }}
        >
          We mint each piece as an NFTs so that you can own them forever. This process is carbon negative.
          {projectId == SENTIMENTS_PROJECT_ID &&
            ' Note: generating these pieces involves submitting messages to the DALLE 3 API.'}
        </Typography>
      </Box>
    </Box>
  );
}

export default MintTitleSection;
