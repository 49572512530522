import React from 'react';
import { useController, Control } from 'react-hook-form';
import { TextField as MuiTextField, TextFieldProps } from '@mui/material';

type Props = {
  control?: Control | any;
  name: string;
};

export function TextField(props: Props & Partial<TextFieldProps>) {
  const { control, name, id, helperText, variant = 'outlined', ...textFieldProps } = props;

  const {
    field: { ref: inputRef, ...inputProps },
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  const errorProps: Partial<TextFieldProps> = {
    variant,
    helperText: error?.message || helperText,
    id: `${variant}-error-helper-text-${name}` || id,
    error: !!error,
  };

  // @ts-ignore
  return React.createElement(MuiTextField, {
    ...inputProps,
    ...textFieldProps,
    ...errorProps,
    inputRef,
  });
}
