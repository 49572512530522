import { Box } from '@mui/material';
import { useEffect, useState } from 'react';

import Mint from 'components/MintPage/index';
import { SNOWFLAKE_PROJECT_ID } from 'config';

// Updated Props interface
interface Props {
  id: string;
  setSnowflakePage: (value: boolean) => void; // Define the type for setSnowflakePage
  snowflakePage: boolean; // Define the type for snowflakePage
}

const ProjectDetails = ({ id, setSnowflakePage, snowflakePage }: Props) => {
  useEffect(() => {
    if (id === SNOWFLAKE_PROJECT_ID) {
      setSnowflakePage(true);
    } else {
      setSnowflakePage(false);
    }
  }, [id, setSnowflakePage]);

  return (
    <Box sx={{ width: '80%', margin: '0 auto' }}>
      <Box>
        <Mint projectId={id} snowflakePage={snowflakePage} />
      </Box>
    </Box>
  );
};

export default ProjectDetails;
