// @ts-nocheck
import { Box, Button, Card, CardMedia, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Loading from 'components/Loading';
import Page from 'components/Page';
import LeftArrowIcon from 'components/icon/LeftArrowIcon';
import RightArrowIcon from 'components/icon/RightArrowIcon';
import Footer from 'components/pages/Home/Footer';
import { ORIGINAL_PROJECT_ID, SENTIMENTS_PROJECT_ID, SNOWFLAKE_PROJECT_ID } from 'config';
import { useFetchCardList } from 'hooks/query/useFetchProjectId';
import { useEffect, useRef, useState } from 'react';
import Carousel, { consts } from 'react-elastic-carousel';
import { Link } from 'react-router-dom';
import Art1 from '../assets/images/art1.jpg';
import Art2 from '../assets/images/art2.jpg';
import Art3 from '../assets/images/art3.jpg';

const useStyles = makeStyles({
  slideOne: {
    display: 'flex',
    flexDirection: 'column',

    '& .bigText': {
      fontWeight: 700,
      fontFamily: 'Metropolis',
      fontSize: 29,
    },

    '& .smallText': {
      fontFamily: 'Inter',
      color: '#BFBFBF',
      fontSize: 14,
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    flex: 1,

    '& img': {
      objectFit: 'cover',
    },

    '& .rec .rec-slider-container': {
      width: 950,
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      justifyContent: 'center',
    },

    '& .rec .rec-carousel': {
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      alignItems: 'center',

      '& button': {
        width: '70px',
        height: '70px',
        borderRadius: '50%',
      },
    },

    '& .rec.rec-carousel-item': {
      display: 'flex',
      alignItems: 'center',
    },
  },

  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },

  info: {
    width: '100%',

    '& .rec .rec-slider-container': {
      width: '100%',
    },

    '& .rec.rec-slider-container': {
      height: 'inherit',
    },
  },

  cardInfo: {
    minHeight: '48px',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0px 37px 0px 42px',
  },

  endSlide: {
    display: 'flex',
    flexDirection: 'column',
    width: '90%',
    gap: 25,

    '& a': {
      textDecoration: 'none',
    },
  },
  boxItemInEndSlide: {
    display: 'flex',
    alignItems: 'center',
    gap: 40,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#312F2F',
    },
  },
});

function SlideOne({ classes }) {
  return (
    <Box className={classes.slideOne}>
      <Typography className="bigText">WELCOME TO RELEASE No. 1</Typography>
      <Typography className="smallText">
        Three cards released in Beta December 2023. By Light House.{' '}
      </Typography>
    </Box>
  );
}
function CardPage() {
  const { cardList, loading, error, refetch } = useFetchCardList();
  const classes = useStyles();
  const carouselInfoRef = useRef();
  const [snowflakePage, setSnowflakePage] = useState(false);

  const [slideIndex, setSlideIndex] = useState(0);
  const listVideo = [
    process.env.PUBLIC_URL + '/media/Originals-cards-page.mov',
    process.env.PUBLIC_URL + '/media/Snowflake-demo-cards-page.mov',
    process.env.PUBLIC_URL + '/media/Mosaics-cards-page-demo.mov',
  ];

  const dummyCardList = [
    {
      skip: true,
    },
    {
      projectId: ORIGINAL_PROJECT_ID,
      projectName: 'Originals',
      description: 'Our first greeting card collection.',
      shortDes: 'The first Light House greeting card.',
      image: Art1,
      url: listVideo[0],
    },
    {
      projectId: SNOWFLAKE_PROJECT_ID,
      projectName: 'Snowflakes',
      description: 'Algorithmically generated snowflakes.',
      image: Art2,
      url: listVideo[1],
      shortDes: 'Unique winter elegance.',
    },
    {
      projectId: SENTIMENTS_PROJECT_ID,
      projectName: 'Mosaics',
      description:
        'An AI powered collection that creates a generative art piece based on the message you write.',
      shortDes: 'A card inspired by your own sentiment. ',
      image: Art3,
      url: listVideo[2],
    },
    {
      skip: true,
    },
  ];

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (slideIndex === 2) setSnowflakePage(true);
    else setSnowflakePage(false);
  }, [slideIndex]);

  const arrow = (data) => {
    const { type, onClick, isEdge } = data;
    const pointer = type === consts.PREV ? !isEdge && <LeftArrowIcon /> : !isEdge && <RightArrowIcon />;

    return (
      <Button onClick={onClick} disabled={isEdge}>
        {pointer}
      </Button>
    );
  };

  const onNextStart = (_, nextItem) => {
    const nextIndex = +nextItem.index;
    setSlideIndex(nextIndex);
  };

  const onPrevStart = (_, nextItem) => {
    const prevIndex = +nextItem.index;
    setSlideIndex(prevIndex);
  };

  const styleOffsetSnowFlake = {
    outline: '20px solid white',
    'outline-offset': '-20px',
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        backgroundColor: `${snowflakePage ? 'white' : 'black'}`,
      }}
    >
      <Page removeFooter snowflakePage={snowflakePage} fullScreenHeight={false}>
        <Box className={classes.container}>
          {loading && <Loading />}
          {!loading && dummyCardList && (
            <>
              <Box className={classes.content}>
                <Carousel
                  onNextStart={onNextStart}
                  onPrevStart={onPrevStart}
                  itemsToShow={1}
                  pagination={false}
                  style={{ flex: 1 }}
                  renderArrow={arrow}
                >
                  <SlideOne classes={classes} />
                  {dummyCardList.map((c, index) => {
                    if (c.skip) {
                      return;
                    }
                    return (
                      <Box key={c.projectName}>
                        {/* <img style={{ width: '539px', height: '542px' }} alt={c.projectName} src={c.url} /> */}
                        <Card sx={{ boxShadow: 'unset' }}>
                          <CardMedia
                            style={{
                              width: '542px',
                              height: '542px',
                              outline: snowflakePage ? '20px solid white' : "unset",
                              'outline-offset': snowflakePage ? '-20px' : "unset",
                            }}
                            autoPlay
                            loop
                            muted
                            type={'video/quicktime'}
                            component={'video'}
                          >
                            <source src={c.url} />
                          </CardMedia>
                        </Card>
                      </Box>
                    );
                  })}
                  <Box className={classes.endSlide}>
                    {dummyCardList.map((c) => {
                      if (c.skip) {
                        return <></>;
                      }
                      return (
                        <Link
                          to={`/cards/${c.projectId}`}
                          className={classes.boxItemInEndSlide}
                          key={c.projectName}
                        >
                          <img
                            style={{ width: '100px', height: '100px' }}
                            alt={c.projectName}
                            src={c.image}
                          />
                          <Box sx={{ width: '302px', display: 'flex', flexDirection: 'column', gap: '5px' }}>
                            <Typography sx={{ fontFamily: 'Metropolis', fontSize: '32px', fontWeight: 700 }}>
                              {c.projectName}
                            </Typography>
                            <Typography
                              sx={{
                                fontFamily: 'Inter',
                                fontSize: '14px',
                                fontWeight: 400,
                                color: '#bfbfbf',
                              }}
                            >
                              {c.shortDes}
                            </Typography>
                          </Box>
                          <Box>
                            <RightArrowIcon />
                          </Box>
                        </Link>
                      );
                    })}
                  </Box>
                </Carousel>
              </Box>
            </>
          )}
        </Box>
      </Page>
      <Box className={classes.info}>
        {dummyCardList.slice(slideIndex, slideIndex + 1).map((c) => {
          if (c.skip) {
            return <Footer />;
          }

          return (
            <Box className={classes.cardInfo}>
              {!c.skip && (
                <>
                  <Typography
                    sx={{
                      color: snowflakePage ? 'black' : 'white',
                      fontFamily: 'Inter',
                      fontSize: 16,
                      fontWeight: 700,
                      textTransform: 'uppercase',
                    }}
                  >
                    {c?.projectName} |{' '}
                    <span
                      style={{
                        textTransform: 'capitalize',
                        fontWeight: 400,
                        color: snowflakePage ? 'black' : 'white',
                      }}
                    >
                      {c.description}
                    </span>
                  </Typography>
                  <Link to={`/cards/${c.projectId}`}>
                    <Typography
                      sx={{
                        color: snowflakePage ? 'black' : 'white',
                        fontFamily: 'Metropolis',
                        fontSize: 32,
                        fontWeight: 700,
                        textTransform: 'uppercase',
                      }}
                    >
                      Enter
                    </Typography>
                  </Link>
                </>
              )}
            </Box>
          );
        })}
      </Box>
    </Box>
  );
}

export default CardPage;
