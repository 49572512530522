// Add this import line at the top
import { CrossmintPaymentElement } from '@crossmint/client-sdk-react-ui';
import { CROSSMINT_COLLECTION_ID, CROSSMINT_ENVIRONMENT, CROSSMINT_PROJECT_ID } from 'config';
import { toast } from 'react-toastify';

function CrossMintPageElement({
  email,
  totalPrice,
  projectId,
  encryptedMessageData,
  coreContractAddress,
  walletAccount,
  whArgsSerialized,
}: {
  email: string;
  totalPrice: string;
  projectId: string;
  encryptedMessageData: string;
  coreContractAddress: string;
  walletAccount: string;
  whArgsSerialized: string;
}) {
  function onEvent(event: any) {
    switch (event.type) {
      case 'payment:process.succeeded':
        toast(`Transaction is processing. Please check your email later.`, { type: 'success' });
        break;
      case 'transaction:fulfillment.succeeded':
        toast(`Transaction succeeded`, { type: 'success' });
        break;
      case 'order:process.finished':
        toast(`Order finished`, { type: 'success' });
        break;
      default:
        break;
    }
  }
  return (
    <div>
      <CrossmintPaymentElement
        onEvent={onEvent}
        projectId={CROSSMINT_PROJECT_ID as string}
        collectionId={CROSSMINT_COLLECTION_ID as string}
        environment={CROSSMINT_ENVIRONMENT as string}
        recipient={{
          email: email,
          wallet: walletAccount,
        }}
        paymentMethod="fiat"
        // currency="_CURRENCY_"  // USD only, more coming soon
        locale="en-US" // en-US only, more coming soon
        uiConfig={{
          colors: {
            background: '#000',
            backgroundSecondary: '#000',
            backgroundTertiary: '#BFBFBF',
            textPrimary: '#FFFFFF',
            textSecondary: '#000',
            accent: '#FFFFFF',
            danger: 'red',
            textLink: '#BFBFBF',
          },
          fontSizeBase: '0.91rem',
          spacingUnit: '0.274rem',

          fontWeightPrimary: '700',
          fontWeightSecondary: '500',
        }}
        mintConfig={{
          totalPrice: totalPrice,
          _projectId: projectId,
          // _coreContract: '0x45aF9A18239F5b354d1C85aF0e014D1c3013D89A',
          _encryptedMessageData: encryptedMessageData,

          //   type: "erc-721",
          //   amount: "_NUMBER_OF_NFTS_",
          //   totalPrice: "_PRICE_IN_NATIVE_TOKEN_",
          // your custom minting arguments...
        }}
        whPassThroughArgs={whArgsSerialized}

        // onEvent={onEvent}
      />
    </div>
  );
}

export default CrossMintPageElement;
