import { ApolloClient, ApolloProvider, InMemoryCache } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import { RainbowKitProvider, getDefaultWallets, midnightTheme } from '@rainbow-me/rainbowkit';
import '@rainbow-me/rainbowkit/styles.css';
import { EXPECTED_CHAIN_ID, GRAPHQL_URL, INFURA_KEY, WALLET_CONNECT_PROJECT_ID } from 'config';
import { QueryClient, QueryClientProvider } from 'react-query';
import theme from 'theme';
import { WagmiConfig, configureChains, createClient } from 'wagmi';
import { arbitrum, arbitrumGoerli, goerli } from 'wagmi/chains';
import { infuraProvider } from 'wagmi/providers/infura';
import { publicProvider } from 'wagmi/providers/public';

const client = new ApolloClient({
  uri: GRAPHQL_URL,
  cache: new InMemoryCache(),
});

const expectedChains = [arbitrum, arbitrumGoerli, goerli];
const initialChain =
  arbitrum.id == EXPECTED_CHAIN_ID
    ? arbitrum
    : arbitrumGoerli.id == EXPECTED_CHAIN_ID
    ? arbitrumGoerli
    : goerli;

const { chains, provider, webSocketProvider } = configureChains(expectedChains, [
  infuraProvider({ apiKey: INFURA_KEY, priority: 0 }),
  publicProvider({ priority: 1 }),
]);

const { connectors } = getDefaultWallets({
  appName: 'Light House',
  chains,
  projectId: WALLET_CONNECT_PROJECT_ID,
});

const wagmiClient = createClient({
  autoConnect: true,
  connectors: [connectors()[0], connectors()[3], connectors()[5]],
  provider,
  webSocketProvider,
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

interface Props {
  children: React.ReactNode;
}

const AppProvider = ({ children }: Props) => {
  return (
    <QueryClientProvider client={queryClient}>
      <WagmiConfig client={wagmiClient}>
        <RainbowKitProvider
          chains={chains}
          initialChain={initialChain}
          theme={midnightTheme({
            borderRadius: 'medium',
          })}
        >
          <ApolloProvider client={client}>
            <ThemeProvider theme={theme}>
              <CssBaseline />
              {children}
            </ThemeProvider>
          </ApolloProvider>
        </RainbowKitProvider>
      </WagmiConfig>
    </QueryClientProvider>
  );
};

export default AppProvider;
