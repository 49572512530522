export const mainnetContractConfig = [
  {
    CONTRACT_VERSION: 'V3',
    GEN_ART_721_CORE_V3_ENGINE_LOGIC: '0xd1d1222f6d3e4f64db1c025ecd0b314db8449ac4',
    MINT_CONTRACT_ADDRESS: '0x94560abecb897f359ee1a6ed0e922315da11752d',
    MEDIA_URL: 'https://plottables-mainnet.s3.amazonaws.com',
    TOKEN_URL: 'https://token.artblocks.io',
    GENERATOR_URL: 'https://generator.artblocks.io',
    EDIT_PROJECT_URL: 'https://artblocks.io/engine/fullyonchain/projects',
  },
  {
    CONTRACT_VERSION: 'V2',
    GEN_ART_721_CORE_V3_ENGINE_LOGIC: '0x18de6097ce5b5b2724c9cae6ac519917f3f178c0',
    MINT_CONTRACT_ADDRESS: '0xe6e728361b7c824cba64cc1e5323efb7a5bb65da',
    MEDIA_URL: 'https://plottables-flex-mainnet.s3.amazonaws.com',
    TOKEN_URL: 'https://token.artblocks.io',
    GENERATOR_URL: 'https://generator.artblocks.io',
    EDIT_PROJECT_URL: 'https://artblocks.io/engine/flex/projects',
  },
];

export const testnetContractConfig = [
  {
    CONTRACT_VERSION: 'V3',
    GEN_ART_721_CORE_V3_ENGINE_LOGIC: '0xCEd5350f5a2Ba24946F92C08260931CFf65dc954',
    MINT_CONTRACT_ADDRESS: '0x0AB754254d7243315FFFDC363a6A0997aD9c3118',
    MEDIA_URL: 'https://plottablesv3-goerli.s3.amazonaws.com',
    TOKEN_URL: 'https://token.staging.artblocks.io',
    GENERATOR_URL: 'https://generator-staging-goerli.artblocks.io',
    EDIT_PROJECT_URL: 'https://artist-staging.artblocks.io/engine/fullyonchain/projects',
  },
  {
    CONTRACT_VERSION: 'V3',
    GEN_ART_721_CORE_V3_ENGINE_LOGIC: '0x901b12016B02D6D932256B2C608beb4730ef6B21',
    MINT_CONTRACT_ADDRESS: '0x192151390006AFA5582A3c674D6c228A2241b0C0',
    MEDIA_URL: 'https://plottablesv3-goerli.s3.amazonaws.com',
    TOKEN_URL: 'https://token.staging.artblocks.io',
    GENERATOR_URL: 'https://generator-staging-goerli.artblocks.io',
    EDIT_PROJECT_URL: 'https://artist-staging.artblocks.io/engine/fullyonchain/projects',
  },
];
