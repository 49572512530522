// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box } from '@mui/material';
import useWallet from 'hooks/Wallet/useWallet';
import p5 from 'p5';
import { memo, useEffect, useRef, useState } from 'react';
import { TokenData } from 'types/common';
import { getCoreGenArt } from 'utils/contract/common';
import Loading from './Loading';

const SketchArtOriginal = ({
  tokenData,
  text = '',
  parentId,
  size = 'normal',
}: {
  tokenData: TokenData | undefined;
  text?: string;
  parentId: string;
  size?: 'small' | 'normal';
}) => {
  const sketchRef = useRef<HTMLDivElement>(null);
  const p5Instance = useRef<p5 | null>(null);
  const displayText = useRef('');
  const [isLoading, setIsLoading] = useState(false);

  const { provider, chainId } = useWallet();
  const coreContractInfo = getCoreGenArt(chainId);
  const [parentElm, setParentElm] = useState<any>(null);
  const handleSetupWithText = (text) => {
    if (!text) return;
    if (window.setupWithText) return window?.setupWithText(text || '');
  };

  useEffect(() => {
    const parent = window.document.getElementById(parentId);
    setParentElm(parent);
  }, [window.document.getElementById(parentId)]);

  useEffect(() => {
    const sketch = async (p: p5) => {
      setIsLoading(true);
      try {
        document.addEventListener('touchstart', {});

        let egHash = tokenData.hash;

        const hashPairs = [];
        for (let j = 0; j < 32; j++) {
          hashPairs.push(egHash.slice(2 + j * 2, 4 + j * 2));
        }

        // Parse the hash pairs into ints. Hash pairs are base 16 so "ec" becomes 236.
        // Each pair will become a value ranging from 0 - 255
        const decPairs = hashPairs.map((x) => {
          return parseInt(x, 16);
        });

        // Grab the first 16 values of the hash to use as a noise seed.
        const seed = parseInt(egHash.slice(0, 16), 16);

        let rColor1;
        let gColor1;
        let bColor1;

        let rColor2;
        let gColor2;
        let bColor2;

        let rColor3;
        let gColor3;
        let bColor3;

        let flowField;
        let system;
        let b;
        let D;

        let transparency;
        let visible;
        let longMessage = 'Dear Ben,\n\nHappy Birthday!\n\nLove,\n\nAlex';
        let shortMessage = 'HAPPY BIRTHDAY';
        let currentCharacter = 0;
        let margin;
        let idealCount = 3200;

        let w = 620;
        let h = 620;

        let toggle = 0;
        let tWidth;

        let toggleLock = true;
        window.setupWithText = function (text) {
          toggleLock = false;
          const tmp = text.split('#%zxy@&3');
          shortMessage = p.trim(tmp[0] || '');
          longMessage = p.trim(tmp[1] || '');
          p.setup();
        };
        p.setup = function setup() {
          rColor1 = decPairs[22];
          gColor1 = decPairs[23];
          bColor1 = p.map(decPairs[24], 0, 255, 100, 255);

          rColor2 = decPairs[25];
          gColor2 = decPairs[26];
          bColor2 = p.map(decPairs[27], 0, 255, 100, 255);

          rColor3 = decPairs[28];
          gColor3 = decPairs[29];
          bColor3 = p.map(decPairs[30], 0, 255, 100, 255);
          p.noiseSeed(seed);
          visible = true;
          transparency = 9;

          b = 255;
          if (p.windowWidth >= p.windowHeight) {
            D = p.windowHeight;
          } else {
            D = p.windowWidth;
          }
          D = 620;
          p.createCanvas(D, D);
          p.textSize(p.width / 35);
          margin = D / 15;

          flowField = new FlowField(5);
          system = new ParticleSystem(0, 0);
          p.background(0);
          p.rectMode(p.CENTER);
          p.textAlign(p.LEFT, p.CENTER);
          if (p.textWidth(longMessage) >= p.width - 2 * margin) {
            tWidth = p.width - 2 * margin;
          } else {
            tWidth = p.textWidth(longMessage);
          }
        };

        p.draw = function draw() {
          if (!visible && transparency < 255) {
            transparency += 3;
          } else if (visible && transparency > 9) {
            transparency -= 5;
            if (transparency < 9) {
              transparency = 9;
            }
          }

          system.run();
          p.textStyle(p.NORMAL);
          p.background(0, transparency);
          p.noFill();
          p.strokeWeight(30);
          p.stroke(0);
          let currentString = longMessage.substring(0, currentCharacter);
          let portion;
          if (transparency > 150) {
            if (currentCharacter < currentString.length + 2) {
              currentCharacter += 0.5;
            }
          } else {
            if (currentCharacter > 0) {
              currentCharacter -= longMessage.length / 30;
            }
          }
          p.stroke(p.color(0, 0, 0, 0));
          p.fill(255);
          p.text(currentString, p.width / 2, p.height / 2, tWidth * 1.07);
        };

        class FlowField {
          constructor(resolution) {
            this.resolution = resolution;
            this.cols = p.width / this.resolution;
            this.rows = p.height / this.resolution;
            this.field = this.createField();
          }

          createField() {
            p.noiseDetail(20, 0.5);
            const field = [];
            const scale = p.map(decPairs[1], 0, 255, 1, 7) * 0.0009 * (10 / p.width);
            const angleOffset = 1; // Adjust the angle offset to control the range of angles (0 to 1)

            for (let i = 0; i < this.cols; i++) {
              field[i] = [];
              for (let j = 0; j < this.rows; j++) {
                const angle = p.noise(i * scale, j * scale) * p.TWO_PI;
                const angleMapped = p.map(angle, 0, 1, 0, 180);
                const vector = p5.Vector.fromAngle(angleMapped);
                field[i][j] = vector;
              }
            }

            return field;
          }

          lookup(lookupVector) {
            const column = Math.floor(p.constrain(lookupVector.x / this.resolution, 0, this.cols - 1));
            const row = Math.floor(p.constrain(lookupVector.y / this.resolution, 0, this.rows - 1));
            return this.field[column][row].copy();
          }

          generate() {
            for (let i = 0; i < this.cols; i++) {
              for (let j = 0; j < this.rows; j++) {
                const angle = p.noise(i * 0.1, j * 0.1) * p.TWO_PI;
                this.field[i][j] = p5.Vector.fromAngle(angle);
              }
            }
          }
          getField() {
            return this.field;
          }
        }

        p.touchStarted = function touchStarted() {
          if (p.mouseX > 0 && p.mouseY > 0 && p.mouseX < p.width && p.mouseY < p.height) {
            if (toggle < 2 && !toggleLock) {
              toggle++;
            } else {
              toggle = 0;
            }
            if (toggle == 1) {
              visible = true;
              ParticleSystem.messageClick = false;
              ParticleSystem.title = true;
            } else if (toggle == 2) {
              ParticleSystem.title = false;
              setTimeout(() => {
                visible = false;
                ParticleSystem.messageClick = true;
              }, 1000);
            } else if (toggle == 0) {
              visible = true;
              ParticleSystem.messageClick = false;
              ParticleSystem.title = false;
            }
          }

          return false;
        };
        class Particle {
          constructor(r, x, y, color1) {
            this.pos = p.createVector(p.random(p.width), p.random(p.height));
            this.target = p.createVector(x, y);
            this.vel = p5.Vector.random2D();
            this.acc = p.createVector();
            this.r = r;
            this.maxspeed = 1.2;
            this.maxforce = 1;
            this.revealMessage = false;
            this.particleColor = color1;
            this.visible = true;
            this.timer = 200;
            this.alphaVal = 255;
            this.intro = false;
          }

          updateColor(color) {
            this.particleColor = color;
          }

          updateIntro(option) {
            this.intro = option;
          }

          updateReveal(option) {
            this.revealMessage = option;
          }

          behaviors() {
            if (this.intro) {
              this.maxspeed = 2;
              var arrive = this.arrive(this.target);
              var mouse = p.createVector(p.mouseX, p.mouseY);
              arrive.mult(1);
              this.applyForce(arrive);
            } else if (this.revealMessage) {
              this.maxspeed = 2;
            } else {
              this.maxspeed = 1.3;
              let noiseScale = 0.01;
              let noiseVal = p.noise(this.pos.x * noiseScale, this.pos.y * noiseScale);
              let angle = p.map(noiseVal, 0, 1, -p.PI, p.PI);
              let forceVariation = p5.Vector.fromAngle(angle);
              this.applyForce(flowField.lookup(this.pos));
            }
          }

          applyForce(f) {
            this.acc.add(f);
          }

          update() {
            p.frameRate(40);
            this.particleColor.setAlpha(this.alphaVal);
            this.prevPos = this.pos.copy();
            this.vel.add(this.acc);
            this.vel.limit(this.maxspeed);
            this.pos.add(this.vel);
            this.acc.mult(0);

            if (
              this.pos.x < margin ||
              this.pos.x > p.width - margin ||
              this.pos.y < margin ||
              this.pos.y > p.height - margin
            ) {
              this.prevPos.x = p.random(margin, p.width - margin);
              this.prevPos.y = p.random(margin, p.height - margin);

              this.pos = this.prevPos;

              this.reColor();
            }
            p.colorMode(p.RGB);
          }

          reColor() {
            if (this.pos.y < p.height / 3) {
              //this.particleColor = color('#0047ab');
              this.particleColor = p.color(rColor1, gColor1, bColor1);
            } else if (this.pos.y < (2 * p.height) / 3) {
              this.particleColor = p.color(rColor2, gColor2, bColor2);
              //this.particleColor = color('#ab81cd');
            } else if (this.pos.y < p.height) {
              //this.particleColor = color(213, 173, 54);
              this.particleColor = p.color(rColor3, gColor3, bColor3);
            }
          }

          flee(target) {
            var desired = p5.Vector.sub(target, this.pos);
            var d = desired.mag();
            if (d < 50) {
              desired.setMag(this.maxspeed);
              desired.mult(-1);
              var steer = p5.Vector.sub(desired, this.vel);
              steer.limit(this.maxforce);
              return steer;
            } else {
              return p.createVector(0, 0);
            }
          }

          show() {
            p.stroke(this.particleColor);
            p.strokeWeight(this.r);
            p.line(this.prevPos.x, this.prevPos.y, this.pos.x, this.pos.y);
          }

          arrive(target) {
            var desired = p5.Vector.sub(target, this.pos);
            var d = desired.mag();
            var speed = this.maxspeed;
            if (d < 50) {
              speed = p.map(d, 0, 100, 0, this.maxspeed);
            }
            desired.setMag(speed);
            var steer = p5.Vector.sub(desired, this.vel);
            steer.limit(this.maxforce);
            return steer;
          }
        }

        class ParticleSystem {
          constructor(x, y) {
            this.title = false;
            this.messageClick = false;
            this.origin = p.createVector(x, y);
            this.particles = [];
            this.grid = [];
            this.size = p.width / 300;
            this.spacing = p.width / 230;
            this.lengthX = Math.floor(p.width / (this.size + this.spacing));
            this.lengthY = Math.floor(p.height / (this.size + this.spacing));
            this.createGrid();
            this.createParticles();
            this.i = 0;
          }

          createGrid() {
            const startX = margin;
            const endX = p.width - margin;
            const startY = margin;
            const endY = p.height - margin;

            this.grid = [];
            let i = 0;
            let k = 0;
            for (let x = startX; x < endX; x += this.spacing) {
              k = 0;
              i++;
              this.grid[i] = [];

              for (let y = startY; y < endY; y += this.spacing) {
                k++;
                this.grid[i][k] = {
                  pos: { x: x, y: y },
                  color: p.color(0),
                  flowDirection: p.createVector(0, 0),
                };
              }
            }
          }

          createParticles() {
            let buffer = p.createGraphics(p.width, p.height);
            buffer.rectMode(p.CENTER);

            buffer.background(0);
            buffer.textSize(p.width / 10);
            buffer.textStyle(p.BOLD);
            buffer.fill(255);

            buffer.textAlign(p.CENTER, p.CENTER);

            buffer.text(shortMessage, p.width / 2, p.height / 2, p.width - 2 * margin, p.height - 2 * margin);

            buffer.loadPixels();

            while (p.floor(idealCount / this.particles.length) - 1 > 0) {
              this.grid.forEach((particleRow) => {
                particleRow.forEach((particle) => {
                  const x = particle.pos.x;
                  const y = particle.pos.y;
                  const density = p.pixelDensity();

                  // Ensure the position is within the buffer
                  if (x >= 0 && y >= 0 && x < buffer.width && y < buffer.height) {
                    // Calculate the index for the pixel array
                    const index = 4 * (p.floor(y) * density * buffer.width * density + p.floor(x) * density);

                    const r = buffer.pixels[index];
                    const g = buffer.pixels[index + 1];
                    const b = buffer.pixels[index + 2];
                    if (r > 100 && g > 100 && b > 100) {
                      this.particles.push(new Particle(this.size, x, y, p.color('white')));
                    }
                  }
                });
              });
            }
            if (this.particles.length < idealCount) {
              const particlesNeeded = idealCount - this.particles.length;
              const particlesToClone = this.particles.slice(0, particlesNeeded);

              this.particles.push(...particlesToClone);
            }
          }

          titleToggle(option) {
            for (let particle of this.particles) {
              particle.updateIntro(option);
            }
          }

          run() {
            for (let particle of this.particles) {
              particle.updateIntro(ParticleSystem.title);
              particle.updateReveal(ParticleSystem.messageClick);
              particle.behaviors();
              particle.update();
              particle.show();
            }
          }
        }

        p5Instance.current = p;
      } catch (error) {
        console.log('Error sketch art: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (parentElm) {
      const children = parentElm.children;

      if (children.length <= 1) new p5(sketch, parentElm);
    }
    return () => {
      if (p5Instance.current) {
        p5Instance.current.remove();
      }
    };
  }, [parentId, size, text, tokenData, tokenData?.hash, parentElm]);

  useEffect(() => {
    displayText.current = text;
  }, [text]);

  handleSetupWithText(text);

  return (
    <Box style={{ width: '270px' }}>
      {isLoading ? (
        <Box p={1}>
          <Loading />
        </Box>
      ) : (
        <Box>
          <div className="art" ref={sketchRef}></div>
        </Box>
      )}
    </Box>
  );
};

export default memo(SketchArtOriginal);
