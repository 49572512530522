import { Provider } from '@wagmi/core';
import { CoreContractV3ABI } from 'abi';
import { INFO_BASED_ON_CHAIN } from 'constants/networkChainId';
import { Contract, ethers } from 'ethers';

export const getTokenHash = async (tokenId: string, provider: Provider, currentChainId: number) => {
  const expectedChainHex = ethers.utils.hexValue(currentChainId);
  const contract = INFO_BASED_ON_CHAIN[expectedChainHex].GEN_ART_721_CORE_V3_ENGINE_LOGIC;
  const coreContract = new Contract(contract, CoreContractV3ABI, provider);
  const tokenHash = await coreContract.tokenIdToHash(tokenId);
  return tokenHash;
};

export const getProjectId = async (tokenId: string, provider: Provider, currentChainId: string) => {
  const contract = INFO_BASED_ON_CHAIN[currentChainId].GEN_ART_721_CORE_V3_ENGINE_LOGIC;
  const coreContract = new Contract(contract, CoreContractV3ABI, provider);
  const projectId = await coreContract.tokenIdToProjectId(tokenId);
  return Number(projectId.toString());
};
