import { Box, Button } from '@mui/material';
import { ConnectButton } from '@rainbow-me/rainbowkit';

const ConnectButtonCustom = ({
  disabled = false,
  label = 'PAY WITH ETH',
  snowflakePage,
}: {
  disabled?: boolean;
  label?: string;
  snowflakePage?: boolean;
}) => {
  return (
    <ConnectButton.Custom>
      {({
        account,
        chain,
        openAccountModal,
        openChainModal,
        openConnectModal,
        authenticationStatus,
        mounted,
      }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
        const ready = mounted && authenticationStatus !== 'loading';
        const connected =
          ready && account && chain && (!authenticationStatus || authenticationStatus === 'authenticated');

        return (
          <Box
            sx={{ width: '100%', height: '100%' }}
            {...(!ready && {
              'aria-hidden': true,
              style: {
                opacity: 0,
                pointerEvents: 'none',
                userSelect: 'none',
              },
            })}
          >
            {(() => {
              if (!connected) {
                return (
                  <Box>
                    <Button
                      onClick={openConnectModal}
                      type="button"
                      sx={{
                        textTransform: 'none',
                        color: snowflakePage ? '#000' : disabled ? 'rgba(255,255,255,0.4)' : '#fff',
                        fontFamily: 'Metropolis',
                        fontSize: '18px',
                        fontWeight: '400',
                        height: '100%',
                        width: '100%',
                      }}
                    >
                      {label}
                    </Button>
                  </Box>
                );
              }

              if (chain.unsupported) {
                return (
                  <button onClick={openChainModal} type="button">
                    Wrong network
                  </button>
                );
              }

              return (
                <Box style={{ display: 'flex', gap: 12 }}>
                  <button
                    onClick={openChainModal}
                    style={{ display: 'flex', alignItems: 'center' }}
                    type="button"
                  >
                    {chain.hasIcon && (
                      <Box
                        style={{
                          background: chain.iconBackground,
                          width: 12,
                          height: 12,
                          borderRadius: 999,
                          overflow: 'hidden',
                          marginRight: 4,
                        }}
                      >
                        {chain.iconUrl && (
                          <img
                            alt={chain.name ?? 'Chain icon'}
                            src={chain.iconUrl}
                            style={{ width: 12, height: 12 }}
                          />
                        )}
                      </Box>
                    )}
                    {chain.name}
                  </button>

                  <button onClick={openAccountModal} type="button">
                    {account.displayName}
                    {account.displayBalance ? ` (${account.displayBalance})` : ''}
                  </button>
                </Box>
              );
            })()}
          </Box>
        );
      }}
    </ConnectButton.Custom>
  );
};

export default ConnectButtonCustom;
