import { LoadingButton } from '@mui/lab';
import { Box, Checkbox, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import { makeStyles } from '@mui/styles';
import ConnectButtonCustom from 'components/ConnectButtonCustom';
import { BACKEND_ACCOUNT } from 'config';
import { Contract, ethers } from 'ethers';
import useGiftingMutation from 'hooks/mutation/useGiftingMutation';
import useWallet from 'hooks/Wallet/useWallet';
import React, { useEffect, useMemo, useState } from 'react';
import { useHref, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { StripeData } from 'types/gifting';
import CrossMintPageElement from './CrossMintPageElement';

const useStyles: () => void = makeStyles((theme: any) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    border: '1px solid rgb(48, 48, 48)',
    position: 'relative',
    backgroundColor: '#000',
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    backdropFilter: 'blur(5px)',
    color: 'rgba(255, 255, 255, 0.8)',
  },
}));

const inputLablePropsStyle = {
  fontSize: '16px',
  fontFamily: 'nunito-sans',
  color: 'rgba(255, 255, 255, 0.44)',
};

const inputPropsStyle = {
  color: '#fff',
  height: 'auto',
  fontFamily: 'nunito-sans',
  backgroundColor: 'rgba(255,255,255,0.21)',
};

const MintModal = ({
  open,
  onClose,
  onMint,
  coreContractInfo,
  encryptMessage,
  isLoading,
  crossMintData,
  isGift = false,
  projectId,
  totalPrice,
  ETHPrice,
  USDPrice,
  isAcceptNotification,
  zipCodeValue,
  LightHouseContract,
  priceInETH,
}: {
  open: boolean;
  onClose: () => void;
  isLoading: boolean;
  onMint: any;
  coreContractInfo: any;
  encryptMessage: Uint8Array | undefined;
  crossMintData?: any;
  isGift?: boolean;
  projectId: string;
  totalPrice: string;
  ETHPrice: number;
  USDPrice: number;
  isAcceptNotification?: any;
  zipCodeValue?: any;
  LightHouseContract?: Contract | null;
  priceInETH?: string;
}) => {
  const classes: any = useStyles();
  const { account, provider } = useWallet();
  const [data, setData] = useState<any>({});
  const [isAgree, setIsAgree] = useState(false);
  const [zipcodeInput, setZipcodeInput] = useState('');
  const [encryptMessageToHex, setEncryptMessageToHex] = useState('');
  const [isAcceptNotificationFromModal, setIsAcceptNotificationFromModal] = useState(false);
  const [stripeLoading, setStripeLoading] = useState(false);
  const [openCrossmintElement, setOpenCrossmintElement] = useState(false);
  const { getStripeData } = useGiftingMutation();
  const handleAcceptedNotification = () => {
    setIsAcceptNotificationFromModal(!isAcceptNotificationFromModal);
    setData((props: any) => {
      return {
        ...props,
        isAcceptedNotification: !data?.isAcceptedNotification,
      };
    });
    isAcceptNotification(!data?.isAcceptedNotification);
  };
  useEffect(() => {
    const getBlock = async () => {
      // const fromBlock = await provider.getBlockNumber();
      const whArgs = {
        ...crossMintData,
        // fromBlock: `${fromBlock - 10}`,
      };

      setData(whArgs);
    };
    getBlock();
  }, [crossMintData, provider]);

  const onChangeZipCode = (e: any) => {
    const { value } = e.target;

    setZipcodeInput(value);
    if (zipCodeValue) zipCodeValue(value);
  };

  useEffect(() => {
    if (!zipcodeInput) toast(`Please enter zip code`, { type: 'warning' });
  }, [zipcodeInput]);

  useEffect(() => {
    if (!isAgree)
      toast(`Please Agree agree to Light House’s Terms and Privacy Policy first.`, { type: 'warning' });
  }, [isAgree]);

  const handleMint = async () => {
    try {
      await onMint();
    } catch (error) {}
  };

  const whArgsSerialized = useMemo(
    () => JSON.stringify({ ...data, zipCode: zipcodeInput, chainId: `${+data.chainId}` }),
    [data, zipcodeInput]
  );
  const handleStripeElement = async () => {
    const stripData: StripeData = {
      projectId: projectId,
      priceInUSD: `${USDPrice * 100}`,
      metadata: JSON.parse(whArgsSerialized),
      // priceInETH,
    };
    try {
      setStripeLoading(true);
      const paymentWithStripe = await getStripeData(stripData);
      if (paymentWithStripe)
        window.open(
          paymentWithStripe?.data,
          '_blank',
          'location=yes,height=570,width=520,scrollbars=yes,status=yes'
        );
    } catch (err) {
    } finally {
      setStripeLoading(false);
    }
  };

  useEffect(() => {
    if (encryptMessage) {
      setEncryptMessageToHex(ethers.utils.hexlify(encryptMessage));
    }
  }, [encryptMessage]);
  return (
    <>
      <Box>
        {open && <Box className={classes.overlay} onClick={onClose} />}
        <Modal
          open={open}
          onClose={onClose}
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          className={classes.modal}
        >
          <Box
            className={classes.paper}
            sx={{
              // width: '457px',
              height: '800px',
            }}
          >
            <Box
              paddingLeft={'23px'}
              sx={{
                borderBottom: '1px solid rgb(48, 48, 48)',
                alignItems: 'left',
              }}
            >
              <Box>
                <Typography
                  id="modal-title"
                  sx={{
                    fontFamily: 'Metropolis',
                    fontSize: '18px',
                    fontWeight: '700',
                    textTransform: 'uppercase',
                    height: 'full',
                    paddingY: '10px',
                  }}
                >
                  CHECKOUT
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: `${openCrossmintElement ? 'flex' : 'unset'}`,
              }}
            >
              <Box marginX={'23px'}>
                <Box marginY={'24px'}>
                  <Typography
                    sx={{
                      fontFamily: 'Metropolis',
                      fontWeight: 700,
                      fontSize: '18px',
                    }}
                  >
                    YOUR ORDER
                  </Typography>
                </Box>
                <Box
                  marginTop={'18px'}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box height={'full'}>
                    <Typography
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '18px',
                        fontWeight: '400',
                        height: 'full',
                        textAlign: 'start',
                        color: '#BFBFBF',
                      }}
                    >
                      Item
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '18px',
                        fontWeight: '400',
                        textAlign: 'start',
                        color: '#BFBFBF',
                      }}
                    >
                      {ETHPrice} ETH
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    marginTop: '13px',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box></Box>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '18px',
                      fontWeight: '400',
                      textAlign: 'right',
                      color: '#BFBFBF',
                    }}
                  >
                    ~${USDPrice}
                  </Typography>
                </Box>
                <Box
                  marginTop={'18px'}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box height={'full'}>
                    <Typography
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '18px',
                        fontWeight: '500',
                        height: 'full',
                        textAlign: 'start',
                        color: '#FFFFFF',
                      }}
                    >
                      Total
                    </Typography>
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '18px',
                        fontWeight: '500',
                        textAlign: 'start',
                        color: '#FFFFFF',
                      }}
                    >
                      {ETHPrice} ETH
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Box></Box>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      marginTop: '13px',
                      fontSize: '18px',
                      fontWeight: '500',
                      textAlign: 'right',
                      color: '#FFFFFF',
                    }}
                  >
                    ~${USDPrice}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginTop: '13px',
                  }}
                >
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: 'Inter',
                        fontSize: '18px',
                        fontWeight: '500',
                        textAlign: 'start',
                        color: '#FFFFFF',
                      }}
                    >
                      US Zip Code
                    </Typography>
                  </Box>
                  <Box>
                    <TextField
                      name="zipCode"
                      type="number"
                      label="Zip code"
                      value={data.zipCode}
                      onChange={onChangeZipCode}
                      InputLabelProps={{
                        style: inputLablePropsStyle,
                      }}
                      InputProps={{
                        style: inputPropsStyle,
                      }}
                      className={`${classes.inputStyle} via`}
                      variant="outlined"
                    />
                  </Box>
                </Box>

                <Box
                  sx={{
                    marginTop: '22px',
                    width: '100%',
                    textAlign: 'right',
                  }}
                >
                  <Typography
                    // component={Link}
                    // to="#"
                    sx={{
                      color: '#BFBFBF',
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      fontSize: '18px',
                    }}
                  >
                    Purchasing On Arbitrum
                  </Typography>
                </Box>
                <Box
                  sx={{
                    marginTop: '26px',
                    width: '100%',
                  }}
                >
                  <Box
                    sx={{
                      width: '100%',
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={isAgree}
                            onChange={() => {
                              setIsAgree(!isAgree);
                            }}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              color: '#BFBFBF',
                              fontFamily: 'Inter',
                              fontWeight: '700',
                              fontSize: '15px',
                            }}
                          >
                            I agree to Light House’s
                            <span> </span>
                            <span
                              style={{
                                textDecoration: 'underline',
                                color: '#BFBFBF',
                                fontFamily: 'Inter',
                                fontWeight: '700',
                                fontSize: '15px',
                              }}
                            >
                              Terms
                            </span>
                            <span> </span>
                            and
                            <span> </span>
                            <span
                              style={{
                                textDecoration: 'underline',
                                color: '#BFBFBF',
                                fontFamily: 'Inter',
                                fontWeight: '700',
                                fontSize: '15px',
                              }}
                            >
                              Privacy Policy.
                            </span>
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            // checked={data?.isAcceptedNotification}
                            checked={isAcceptNotificationFromModal}
                            onChange={handleAcceptedNotification}
                          />
                        }
                        label={
                          <Typography
                            sx={{
                              color: '#BFBFBF',
                              fontFamily: 'Inter',
                              fontWeight: '700',
                              fontSize: '15px',
                            }}
                          >
                            Receive new collection updates (we won’t spam)
                          </Typography>
                        }
                      />
                    </FormGroup>
                  </Box>
                </Box>

                {isGift ? (
                  <Box
                    sx={{
                      width: 'full',
                      height: '75px',
                      marginTop: '30px',
                      marginBottom: '22px',
                      border: '1px solid #FFFFFF',
                      borderRadius: '8px',
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      disabled={!isAgree || !zipcodeInput}
                      loading={stripeLoading}
                      sx={{
                        textTransform: 'up',
                        color: '#fff',
                        fontFamily: 'Metropolis',
                        fontSize: '18px',
                        fontWeight: '400',
                        width: '100%',
                        height: '100%',
                        textAlign: 'center',
                      }}
                      onClick={handleStripeElement}
                    >
                      {!isAgree || !zipcodeInput ? (
                        <Typography
                          sx={{
                            color: 'rgba(255,255,255,0.4)',
                            textTransform: 'up',
                            fontFamily: 'Metropolis',
                            fontSize: '18px',
                            fontWeight: '400',
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          PAY WITH CARD
                        </Typography>
                      ) : (
                        'PAY WITH CARD'
                      )}
                    </LoadingButton>
                  </Box>
                ) : (
                  <div style={{ marginTop: '30px' }}></div>
                )}

                <Box
                  sx={{
                    marginTop: '30px',
                    width: 'full',
                    height: '75px',
                    border: '1px solid #FFFFFF',
                    borderRadius: '8px',
                  }}
                >
                  <LoadingButton
                    type="submit"
                    disabled={!isAgree || !zipcodeInput}
                    sx={{
                      textTransform: 'up',
                      color: '#fff',
                      fontFamily: 'Metropolis',
                      fontSize: '18px',
                      fontWeight: '400',
                      width: '100%',
                      height: '100%',
                      textAlign: 'center',
                    }}
                    loading={isLoading}
                    onClick={handleMint}
                  >
                    {account ? (
                      !isAgree || !zipcodeInput ? (
                        <Typography
                          sx={{
                            color: 'rgba(255,255,255,0.4)',
                            textTransform: 'up',
                            fontFamily: 'Metropolis',
                            fontSize: '18px',
                            fontWeight: '400',
                            width: '100%',
                            textAlign: 'center',
                          }}
                        >
                          PAY WITH ETH
                        </Typography>
                      ) : (
                        'PAY WITH ETH'
                      )
                    ) : (
                      <Box>
                        <ConnectButtonCustom disabled={!isAgree || !zipcodeInput} />
                      </Box>
                    )}
                  </LoadingButton>
                </Box>
                <Box marginTop={'17px'} marginBottom={'20px'}>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontWeight: '400',
                      fontSize: '15px',
                      textAlign: 'center',
                      color: '#BFBFBF',
                    }}
                  >
                    Minting may take a few minutes.
                  </Typography>
                </Box>
                {/* <Button sx={{color:'white'}} onClick={handleOpenMintModal}>Show Success Modal</Button> */}
              </Box>
              <Box>
                {openCrossmintElement && (
                  <Box marginX={'28px'}>
                    <CrossMintPageElement
                      email={crossMintData.senderEmail}
                      totalPrice={totalPrice}
                      projectId={projectId}
                      encryptedMessageData={encryptMessageToHex ? encryptMessageToHex : '0x'}
                      coreContractAddress={coreContractInfo.address}
                      walletAccount={isGift ? BACKEND_ACCOUNT : account ? account : BACKEND_ACCOUNT}
                      whArgsSerialized={whArgsSerialized}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </>
  );
};

export default React.memo(MintModal);
