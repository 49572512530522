import { Box, Typography } from '@mui/material';
import { useState } from 'react';

function Hero() {
  const [isHover, setIsHover] = useState(false);
  return (
    <Box
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={{
        width: '100%',
        minHeight: '200px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        sx={{
          maxWidth: '1018px',
          fontFamily: isHover ? 'Inter' : 'nunito-sans',
          fontSize: isHover ? '18px' : '64px',
          textAlign: isHover ? 'center' : 'unset',
          width: '100%',
          fontWeight: isHover ? 400 : 700,
        }}
      >
        {!isHover
          ? `CONTEMPORARY DIGITAL ART AND GREETING CARDS`
          : 'We are building a platform for contemporary digital artists to release a new form of greeting card. '}
      </Typography>
    </Box>
  );
}

export default Hero;
