import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Box,
  Drawer,
  Fade,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { MenuBarStyled } from 'assets/styled/HomePage/Header';
import { useUserContext } from 'context/UserContext';
import useWallet from 'hooks/Wallet/useWallet';
import useModalState from 'hooks/useModalState';
import * as _ from 'lodash';
import React, { useState } from 'react';
import * as ReactRouter from 'react-router-dom';
import RegisterModal from './RegisterModal';

let useStyles = makeStyles((theme) => ({
  toolbar: {
    width: '100%',
    display: 'flex',
    margin: 'auto',
    justifyContent: 'space-between',

    '&.toolbar': {
      padding: '0 37px 0 42px',
      background: '#000',
    },
  },
}));

let items = [
  {
    label: 'Cards',
    url: '/cards',
    enabled: true,
  },
  {
    label: 'Artists',
    url: '/artists',
    enabled: true,
  },
  {
    label: 'About',
    url: '/about-platform',
    enabled: true,
  },
  {
    label: 'Profile',
    url: '/profile',
    enabled: true,
  },
];
const aboutItem = [
  {
    label: 'PLATFORM',
    url: '/about-platform',
  },
  {
    label: 'OUR TEAM',
    url: '/about-ourteam',
  },
];
type HeaderProps = {
  snowflakePage?: boolean;
};

const Header = ({ snowflakePage }: HeaderProps) => {
  const classes = useStyles();
  const userContext = useUserContext();
  const { connected } = useWallet();
  const [mobileOpen, setMobileOpen] = useState(false);
  const {
    isOpen: isRegisterModalOpen,
    onClose: onResgiterModalClose,
    onOpen: onResgiterModalOpen,
  } = useModalState();

  const isRegister = _.get(userContext, ['isRegister'], false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const menuItemStyle = {
    backgroundColor: '#464646',
    color: '#fff',
    textDecoration: 'none',
    fontFamily: 'Nunito Sans',
    fontSize: '14px',
  };

  const menuLinkStyle = {};

  if (connected) {
    items.map((item) => {
      if (item.label === 'Mint') item.enabled = true;
      return item;
    });
  } else {
    items.map((item) => {
      if (item.label === 'Mint') {
        item.enabled = false;
      }

      return item;
    });
  }

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <List dense>
        {items.map((item) => (
          <ListItem sx={{ '&:hover': { backgroundColor: '#f5f5f5' } }} key={item.label} disablePadding>
            <ListItemButton
              component={Link}
              href={item.url}
              sx={{ textAlign: 'left', pointerEvents: item.enabled ? 'auto' : 'none' }}
            >
              <ListItemText
                primary={item.label}
                primaryTypographyProps={{
                  fontSize: 18,
                  fontWeight: 600,
                  color: item.enabled ? 'black' : 'lightgrey',
                }}
              />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: snowflakePage ? 'white' : 'black',
        paddingTop: { xs: '0px' },
        paddingBottom: { xs: '0px' },
      }}
    >
      <AppBar
        component="nav"
        position="static"
        elevation={1}
        sx={{ backgroundColor: snowflakePage ? 'white' : 'black', boxShadow: 0 }}
      >
        <Toolbar
          className={`${classes.toolbar} toolbar`}
          style={{ backgroundColor: snowflakePage ? 'white' : 'black' }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconButton
              color="default"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
            <Box sx={{ paddingRight: '25px' }}>
              <Link href="/" sx={{ display: 'flex', textDecoration: 'none' }}>
                <Typography
                  sx={{
                    fontSize: {
                      xs: 20,
                      sm: 24,
                    },
                    fontWeight: 'bold',
                    fontFamily: 'Metropolis',
                    color: snowflakePage ? 'black' : 'white',
                  }}
                >
                  LIGHT HOUSE / Beta
                </Typography>
              </Link>
            </Box>
          </Box>
          <MenuBarStyled>
            {items.map((item) => (
              <ReactRouter.NavLink
                style={{
                  fontFamily: 'nunito-sans',
                  textTransform: 'capitalize',
                  fontSize: '14px',
                  color: snowflakePage ? 'black' : 'white',
                  textDecoration: item.label === 'About' ? 'none' : '', // Add this line
                }}
                to={item.label == 'About' ? '' : item.url}
                key={item.label}
                className={({ isActive, isPending }) => (isPending ? 'pending' : isActive ? 'active' : '')}
                // component={item.label=='About' ? 'div' : NavLink}
                // href={item.label=='About' ? '' :item.url}
                id={item.label === 'About' ? 'fade-button' : ''}
                aria-controls={item.label === 'About' ? (open ? 'fade-menu' : undefined) : ''}
                aria-haspopup={item.label === 'About' ? true : false}
                aria-expanded={item.label === 'About' ? (open ? true : undefined) : undefined}
                onClick={item.label === 'About' ? handleClick : () => {}}
              >
                {item.label}
              </ReactRouter.NavLink>
            ))}
          </MenuBarStyled>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 240,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box>
        <Menu
          id="fade-menu"
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          TransitionComponent={Fade}
          PaperProps={{
            sx: {
              padding: '0px',
              backgroundColor: '#464646',
            },
          }}
        >
          {aboutItem.map((it) => (
            <Link sx={menuLinkStyle} href={it.url} key={it.label}>
              <MenuItem sx={menuItemStyle} onClick={handleClose}>
                {it.label}
              </MenuItem>
            </Link>
          ))}
        </Menu>
      </Box>
      <RegisterModal isOpen={isRegisterModalOpen} onClose={onResgiterModalClose} />
    </Box>
  );
};

export default Header;
