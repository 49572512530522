import { Box, Container, Typography } from '@mui/material';
import Header from 'components/Header';
import Footer from 'components/pages/Home/Footer';

function ArtistPage() {
  return (
    <Box
      sx={{
        backgroundColor: '#000',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Header />

      <Container
        maxWidth="xl"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            flex: 1,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box>
            <Typography
              sx={{
                maxWidth: '995px',
                fontFamily: 'Inter',
                fontSize: {
                  xs: '50px',
                  sm: '64px',
                },
                textAlign: 'center',
                fontWeight: 700,
              }}
            >
              We are a platform for artists.
            </Typography>
            <Typography
              sx={{
                maxWidth: '995px',
                fontFamily: 'Inter',
                fontSize: '20px',
                textAlign: 'center',
                fontWeight: 400,
              }}
            >
              Contact us to work together at generativelighthouse@gmail.com
            </Typography>
          </Box>
        </Box>
      </Container>
      <Footer />
    </Box>
  );
}

export default ArtistPage;
