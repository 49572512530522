import MintModalSuccess from 'components/MintPage/MintMondalSuccess';
import Providers from 'components/Providers';
import { UserContextProvider } from 'context/UserContextProvider';
import AboutFAQ from 'pages/AboutFAQ';
import AboutOurTeam from 'pages/AboutOurTeam';
import AboutPage from 'pages/AboutPage';
import AboutPlatformPage from 'pages/AboutPlatformPage';
import ArtistPage from 'pages/ArtistPage';
import CardPage from 'pages/CardPage';
import HomePage from 'pages/HomePage';
import MintPage from 'pages/MintPage';
import ProfilePage from 'pages/ProfilePage';
import ProjectPage from 'pages/ProjectPage';
import ProjectsPage from 'pages/ProjectsPage';
import ReceivePage from 'pages/ReceivePage';
import TokenPage from 'pages/TokenPage';
import UserPage from 'pages/UserPage';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA4 from 'react-ga4';
import { REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID } from 'config';
ReactGA4.initialize(REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID as string);
function App() {
  return (
    <Providers>
      <UserContextProvider>
        <Router>
          <Routes>
            <Route index element={<HomePage />} />
            <Route path="projects" element={<ProjectsPage />} />
            <Route path="project/:contractAddress/:projectId" element={<ProjectPage />} />
            <Route path="token/:id" element={<TokenPage />} />
            <Route path="user/:walletAddress" element={<UserPage />} />
            <Route path="mint" element={<MintPage />} />
            <Route path="receive" element={<ReceivePage />} />
            <Route path="about" element={<AboutPage />} />
            <Route path="about-platform" element={<AboutPlatformPage />} />
            <Route path="about-faq" element={<AboutFAQ />} />
            <Route path="about-ourteam" element={<AboutOurTeam />} />
            <Route path="artists" element={<ArtistPage />} />
            <Route path="cards" element={<CardPage />} />
            <Route path="cards/:projectId" element={<ProjectPage />} />
            <Route path="/profile" element={<ProfilePage />} />
            <Route
              path="/stripe-payment-success"
              element={<MintModalSuccess open={true} onClose={() => {}} isLoading={false} />}
            />
          </Routes>
        </Router>
        <ToastContainer
          hideProgressBar
          autoClose={5000}
          position="bottom-right"
          theme="dark"
          newestOnTop
          pauseOnHover
          pauseOnFocusLoss
        />
      </UserContextProvider>
    </Providers>
  );
}

export default App;
