import axiosClient from 'api/axiosClient';
import {
  ChangeReceiverEmailSchema,
  CollectSchema,
  GetAccessTokenSchema,
  RegisterAndReceiveGiftSchema,
  RequestOTPGiftingSchema,
  StripeData,
  VerifyOTPGiferSchema,
  VerifyOTPReceiverSchema,
} from './../types/gifting';

const baseGiftingUrl = 'gifting';
const baseGiftingGiftUrl = `${baseGiftingUrl}/gift`;
const baseGiftingReceiveUrl = `${baseGiftingUrl}/receive`;

const giftingAPI = {
  collect(schema: CollectSchema) {
    const url = `${baseGiftingUrl}/collect`;

    return axiosClient.post(url, schema);
  },

  verifyOTPGifter({ email, otp }: VerifyOTPGiferSchema) {
    const url = `${baseGiftingGiftUrl}/verifyOTP`;

    return axiosClient.post(url, {
      email,
      passcode: otp,
    });
  },

  registerAndReceiveGift({
    walletAddress,
    signature,
    encryptPublicKey,
    accessToken,
  }: RegisterAndReceiveGiftSchema) {
    const url = `${baseGiftingReceiveUrl}/verifyOTP`;

    return axiosClient.post(
      url,
      {
        receiverWallet: walletAddress,
        signature,
        publicKey: encryptPublicKey,
      },
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
  },

  verifyOTPReceiver({ walletAddress, accessToken }: VerifyOTPReceiverSchema) {
    const url = `${baseGiftingReceiveUrl}/verifyOTP`;

    return axiosClient.post(
      url,
      {
        receiverWallet: walletAddress,
      },
      {
        headers: {
          Authorization: 'Bearer ' + accessToken,
        },
      }
    );
  },

  requestOTP({
    chainId,
    tokenId,
    txHash,
    fromBlock,
    receiverEmail,
    senderFullName,
    isAcceptedNotification,
    message,
    priceInUSD,
    senderEmail,
    zipCode,
  }: RequestOTPGiftingSchema) {
    const url = `${baseGiftingGiftUrl}/request`;

    return axiosClient.post(url, {
      chainId,
      tokenId,
      txHash,
      fromBlock,
      receiverEmail,
      senderFullName,
      isAcceptedNotification,
      message,
      priceInUSD,
      senderEmail,
      zipCode,
    });
  },

  changeReceiverEmail({
    chainId,
    addressGifter,
    tokenId,
    signature,
    receiverEmail,
    message,
  }: ChangeReceiverEmailSchema) {
    const url = `${baseGiftingGiftUrl}/changeReceiverEmail`;

    return axiosClient.post(url, {
      chainId,
      by: addressGifter,
      tokenId,
      signature,
      receiverEmail,
      message,
    });
  },

  getAccessToken({ passcode, emailReceive }: GetAccessTokenSchema) {
    const url = `${baseGiftingReceiveUrl}/getToken`;

    return axiosClient.get(url, {
      params: {
        passcode,
        receiverEmail: emailReceive,
      },
    });
  },

  getGiftingData(accessToken: any) {
    const url = `${baseGiftingGiftUrl}/data`;

    return axiosClient.get(url, {
      headers: {
        Authorization: 'Bearer ' + accessToken,
      },
    });
  },

  getStripe(data: StripeData) {
    const url = `${baseGiftingUrl}/stripe/payment`;
    return axiosClient.post(url, data);
  },
};

export default giftingAPI;
