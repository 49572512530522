import useWallet from 'hooks/Wallet/useWallet';
import useWalletDataApiCall from 'hooks/api/WalletData/useWalletDataApiCall';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import getEncryptPublicKey from 'utils/contract/getEncryptPulicKey';
import signMessage from 'utils/contract/signMessage';

const useWalletDataMutation = () => {
  const { requestOTPCall, verifyOTPCall, getWalletDataCall } = useWalletDataApiCall();
  const { account } = useWallet();

  const requestOTP = useMutation(async (email: string) => await requestOTPCall(email), {
    onSuccess(data, variables, context) {
      console.log({ data, variables, context });
    },
    onError(error: any, variables, context) {
      toast(`${error.data.message}`, { type: 'error' });
    },
  });

  const verifyOTP = useMutation(
    async ({ email, otp }: { email: string; otp: string }) => {
      if (!account) return;
      const signature = await signMessage(account);
      const encryptPublicKey = await getEncryptPublicKey(account);

      return await verifyOTPCall({ email, walletAddress: account, signature, encryptPublicKey, otp });
    },
    {
      onSuccess(data, variables, context) {
        console.log({ data, variables, context });
      },
      onError(error: any, context: any) {
        if (error.message) {
          toast(`${error.message}`, { type: 'error' });
        } else {
          toast(`${error.data.message}`, { type: 'error' });
        }
        console.log({ error });
      },
    }
  );

  const getWalletData = useMutation(
    async ({
      emailOrAddress,
      customErrorMessage,
      hideToast,
    }: {
      emailOrAddress: string;
      customErrorMessage?: string;
      hideToast?: boolean;
    }) => {
      return await getWalletDataCall(emailOrAddress);
    },
    {
      onSuccess(data, variables, context) {
        console.log({ data, variables, context });
      },
      onError(error: any, variables: any, context: any) {
        const { customErrorMessage, hideToast } = variables;

        if (!hideToast) {
          if (customErrorMessage) {
            toast(`${customErrorMessage}`, { type: 'error' });
          } else {
            if (error.message) {
              toast(`${error.message}`, { type: 'error' });
            } else {
              toast(`${error.data.message}`, { type: 'error' });
            }
          }
        }

        console.log({ error });
      },
    }
  );

  return {
    isLoading: requestOTP.isLoading || verifyOTP.isLoading || getWalletData.isLoading,
    requestOTP: requestOTP.mutateAsync,
    verifyOTP: verifyOTP.mutateAsync,
    getWalletData: getWalletData.mutateAsync,
  };
};

export default useWalletDataMutation;
