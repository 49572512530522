import { useCallback } from 'react';
import walletDataAPI from 'services/walletData';
import { VerifyOTPSchema } from 'types/walletData';

function useWalletDataApiCall() {
  const requestOTPCall = useCallback(async (email: string) => await walletDataAPI.requestOTP(email), []);

  const verifyOTPCall = useCallback(
    async ({ email, walletAddress, signature, encryptPublicKey, otp }: VerifyOTPSchema) =>
      await walletDataAPI.verifyOTP({ email, walletAddress, signature, encryptPublicKey, otp }),
    []
  );

  const getWalletDataCall = useCallback(
    async (emailOrAddress: string) => await walletDataAPI.getUserData(emailOrAddress),
    []
  );

  return {
    requestOTPCall,
    verifyOTPCall,
    getWalletDataCall,
  };
}

export default useWalletDataApiCall;
