import axios, { AxiosError } from 'axios';

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL_API + '/api',
  headers: {
    'Content-Type': 'application/json',
  },
});

axiosClient.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (error: Error | AxiosError) {
    if (axios.isAxiosError(error)) {
      if (error.message === 'Network Error') {
        return Promise.reject({
          data: {
            message: 'Network Error',
          },
        });
      }
      const { response } = error;
      return Promise.reject(response);
    } else {
      return Promise.reject(error);
    }
  }
);

export default axiosClient;
