import { mainnetContractConfig, testnetContractConfig } from './contractConfig';

export const EXPECTED_CHAIN_ID = Number(process.env.REACT_APP_EXPECTED_CHAIN_ID);
export const GRAPHQL_URL = process.env.REACT_APP_GRAPHQL_URL;
export const WALLET_CONNECT_PROJECT_ID = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID || '';
export const INFURA_KEY = process.env.REACT_APP_INFURA_KEY || '';
export const PROJECTS_PER_PAGE = 8;
export const TOKENS_PER_PAGE = 9;
export const MULTIPLY_GAS_LIMIT = 1.1;
export const CONTRACT_INFO = EXPECTED_CHAIN_ID === 1 ? mainnetContractConfig : testnetContractConfig;
export const MERKLE_PROOF_API_URL = process.env.REACT_APP_MERKLE_PROOF_API_URL || '';
export const HOLDER_PROOF_API_URL = process.env.REACT_APP_HOLDER_PROOF_API_URL || '';
export const SECRET_KEY = process.env.REACT_APP_SECRECT_MESSAGE;
export const BACKEND_ACCOUNT =
  process.env.REACT_APP_BACKEND_ACCOUNT || '0x679a8e45C0E3310C7d64ADe8a0e7D1462E2Be8cC';
export const ORIGINAL_PROJECT_ID = process.env.REACT_APP_ORIGINAL_PROJECT_ID ?? '0';
export const SNOWFLAKE_PROJECT_ID = process.env.REACT_APP_SNOWFLAKE_PROJECT_ID ?? '1';
export const SENTIMENTS_PROJECT_ID = process.env.REACT_APP_SENTIMENTS_PROJECT_ID ?? '2';
export const CROSSMINT_PROJECT_ID = process.env.REACT_APP_CROSSMINT_PROJECT_ID;
export const CROSSMINT_COLLECTION_ID = process.env.REACT_APP_CROSSMINT_COLLECTION_ID;
export const CROSSMINT_ENVIRONMENT = process.env.REACT_APP_CROSSMINT_ENVIRONMENT;
export const REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID;
