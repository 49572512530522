import { Box, Container } from '@mui/material';

import Header from 'components/Header';
import Footer from './pages/Home/Footer';

interface Props {
  children: React.ReactNode;
  removeFooter?: boolean;
  fullScreenHeight?: boolean;
  snowflakePage?: boolean;
}

const Page = ({ snowflakePage, children, removeFooter = false, fullScreenHeight = true }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: fullScreenHeight ? '100vh' : 'unset',
        backgroundColor: snowflakePage ? '#fff' : '#000',
        flex: 1,
      }}
    >
      <Header snowflakePage={snowflakePage}/>
      <main style={{ flex: 1, display: 'flex' }}>
        <Container maxWidth="xl" sx={{ paddingTop: 1, paddingBottom: 1, flex: 1 }}>
          {children}
        </Container>
      </main>
      {!removeFooter && <Footer />}
    </Box>
  );
};

export default Page;
