import { useQuery } from 'react-query';
import walletDataAPI from 'services/walletData';

const useGetWalletData = (emailOrWalletAddress: string = '') => {
  return useQuery(
    ['get-wallet-data', emailOrWalletAddress],
    async () => await walletDataAPI.getUserData(emailOrWalletAddress),
    {
      enabled: !!emailOrWalletAddress,
    }
  );
};

export default useGetWalletData;

