import { BigNumber, Contract } from 'ethers';
import { ContractInfo } from 'types/common';

export const getProjectAndMinterInfoAt = async (
  projectId: string,
  signerOrProvider: any,
  coreContractInfo: ContractInfo,
  minterFilterV2Info: ContractInfo
) => {
  const MinterFilterV2Contract = new Contract(
    minterFilterV2Info.address,
    minterFilterV2Info.abi,
    signerOrProvider
  );

  const projectAndMinterInfo = await MinterFilterV2Contract.getProjectAndMinterInfoOnContractAt(
    coreContractInfo.address,
    BigNumber.from(projectId)
  );

  return projectAndMinterInfo;
};
