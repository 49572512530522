import React from 'react'
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { Box, Button, Typography } from '@mui/material';

const ConnectProfileButtonCustom = () => {
  return (
    <ConnectButton.Custom >
    {({
      account,
      chain,
      openAccountModal,
      openChainModal,
      openConnectModal,
      authenticationStatus,
      mounted,
    }) => {
      const ready = mounted && authenticationStatus !== 'loading';
      const connected =
        ready &&
        account &&
        chain &&
        (!authenticationStatus ||
          authenticationStatus === 'authenticated');

      return (
        <Box sx={{width:'100%', height:'100%'}}
          {...(!ready && {
            'aria-hidden': true,
            'style': {
              opacity: 0,
              pointerEvents: 'none',
              userSelect: 'none',
            },
          })}
        >
          {(() => {
            if (!connected) {
              return (
                <Box >
                <Button onClick={openConnectModal} type="button" 
                sx={{textTransform:'up', 
                color:'#fff', 
                height:'58px',
                backgroundColor:'#BABABA'
              }} >
                 <Typography sx={{ 
                    fontFamily:'Gotham', 
                    fontSize:'20px', 
                    fontWeight:'400', }}>
                        Connect Wallet
                  </Typography> 
                </Button>
                </Box>
              );
            }

            if (chain.unsupported) {
              return (
                <button onClick={openChainModal} type="button">
                  Wrong network
                </button>
              );
            }

            return (
              <Box style={{ display: 'flex', gap: 12 }}>
                <button
                  onClick={openChainModal}
                  style={{ display: 'flex', alignItems: 'center' }}
                  type="button"
                >
                  {chain.hasIcon && (
                    <Box
                      style={{
                        background: chain.iconBackground,
                        width: 12,
                        height: 12,
                        borderRadius: 999,
                        overflow: 'hidden',
                        marginRight: 4,
                      }}
                    >
                      {chain.iconUrl && (
                        <img
                          alt={chain.name ?? 'Chain icon'}
                          src={chain.iconUrl}
                          style={{ width: 12, height: 12 }}
                        />
                      )}
                    </Box>
                  )}
                  {chain.name}
                </button>

                <button onClick={openAccountModal} type="button">
                  {account.displayName}
                  {account.displayBalance
                    ? ` (${account.displayBalance})`
                    : ''}
                </button>
              </Box>
            );
          })()}
        </Box>
      );
    }}
  </ConnectButton.Custom>
  )
}

export default ConnectProfileButtonCustom