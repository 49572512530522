import { EXPECTED_CHAIN_ID, SENTIMENTS_PROJECT_ID } from 'config';
import { ethers } from 'ethers';
import { useContractRead } from 'wagmi';
import { getCoreGenArt } from './common';

const useProjectExternalById = (projectId: number | string, index: number | string) => {
  const expectedChain = ethers.utils.hexValue(EXPECTED_CHAIN_ID);
  const coreContractInfo = getCoreGenArt(expectedChain);
  const { data, isError, isLoading } = useContractRead({
    address: coreContractInfo.address as `0x${string}`,
    abi: coreContractInfo.abi,
    functionName: 'projectExternalAssetDependencyByIndex',
    args: [projectId || SENTIMENTS_PROJECT_ID, index],
    watch: true,
    chainId: EXPECTED_CHAIN_ID,
    onSuccess(data: any) {},
  });
  return { data, isError, isLoading };
};
export default useProjectExternalById;
