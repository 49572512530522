// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box } from '@mui/material';
import p5 from 'p5';
import { memo, useEffect, useRef, useState } from 'react';
import { TokenDataForSentiments } from 'types/common';
import Loading from './Loading';

const SketchArtSentiments = ({
  tokenData,
  text = '',
  parentId,
  size = 'normal',
}: {
  tokenData: TokenDataForSentiments | undefined;
  text?: string;
  parentId: string;
  size?: 'small' | 'normal';
}) => {
  const sketchRef = useRef<HTMLDivElement>(null);
  const p5Instance = useRef<p5 | null>(null);
  const displayText = useRef('');
  const [isLoading, setIsLoading] = useState(false);
  const [parentElm, setParentElm] = useState<any>(null);

  const handleSetupWithText = () => {
    if (!text) return;
    if (window.setupWithText) return window?.setupWithText(text || '');
  };

  handleSetupWithText();

  useEffect(() => {
    const parent = window.document.getElementById(parentId);
    setParentElm(parent);
  }, [window.document.getElementById(parentId)]);

  useEffect(() => {
    const sketch = async (p: p5) => {
      setIsLoading(true);
      try {
        let img;
        let initialSize;
        let size;
        let D;

        let clicked;
        let shortMessage = '';
        let longMessage = 'Dear Ben,\n\nHappy Birthday!\n\nLove,\n\nAlex';

        let transparency = 0;
        let density;
        let textColor = 0;

        let toggleLock = true;
        let myRandom;

        let tWidth;
        let font;

        document.addEventListener('touchstart', {});

        class Random {
          constructor() {
            this.useA = false;
            let sfc32 = function (uint128Hex) {
              let a = parseInt(uint128Hex.substring(0, 8), 16);
              let b = parseInt(uint128Hex.substring(8, 16), 16);
              let c = parseInt(uint128Hex.substring(16, 24), 16);
              let d = parseInt(uint128Hex.substring(24, 32), 16);
              return function () {
                a |= 0;
                b |= 0;
                c |= 0;
                d |= 0;
                let t = (((a + b) | 0) + d) | 0;
                d = (d + 1) | 0;
                a = b ^ (b >>> 9);
                b = (c + (c << 3)) | 0;
                c = (c << 21) | (c >>> 11);
                c = (c + t) | 0;
                return (t >>> 0) / 4294967296;
              };
            };
            // seed prngA with first half of tokenData.hash
            this.prngA = new sfc32(tokenData.hash.substring(2, 34));
            // seed prngB with second half of tokenData.hash
            this.prngB = new sfc32(tokenData.hash.substring(34, 66));
            for (let i = 0; i < 1e6; i += 2) {
              this.prngA();
              this.prngB();
            }
          }
          // random number between 0 (inclusive) and 1 (exclusive)
          random_dec() {
            this.useA = !this.useA;
            return this.useA ? this.prngA() : this.prngB();
          }
          // random number between a (inclusive) and b (exclusive)
          random_num(a, b) {
            return a + (b - a) * this.random_dec();
          }
          // random integer between a (inclusive) and b (inclusive)
          // requires a < b for proper probability distribution
          random_int(a, b) {
            return Math.floor(this.random_num(a, b + 1));
          }
          // random boolean with p as percent liklihood of true
          random_bool(p) {
            return this.random_dec() < p;
          }
          // random value in an array of items
          random_choice(list) {
            return list[this.random_int(0, list.length - 1)];
          }
        }

        window.setupWithText = function (text) {
          toggleLock = false;
          const tmp = text.split('#%zxy@&3');
          shortMessage = p.trim(tmp[0] || '');
          longMessage = p.trim(tmp[1] || '');
          p.setup;
        };

        p.preload = function preload() {
          let index = tokenData.tokenId - (tokenData.projectId ?? 0) * 1e6;
          let cid = '';
          if (tokenData.externalAssetDependencies?.[index]?.cid)
            cid = tokenData.externalAssetDependencies?.[index]?.cid;
          img = p.loadImage('https://white-magic-hyena-356.mypinata.cloud/ipfs/' + cid);
          img.loadPixels();
          font = p.loadFont(
            'https://white-magic-hyena-356.mypinata.cloud/ipfs/QmeuyvKQW7K3fpQNf9zGrHPWpLtaZrJWq1M8Q2Nq7B9iDC'
          );
        };

        p.setup = function setup() {
          p.textFont(font);
          myRandom = new Random();
          p.background(0);
          if (p.windowWidth >= p.windowHeight) {
            D = p.windowHeight;
          } else {
            D = p.windowWidth;
          }
          D = 620;
          p.createCanvas(620, 620);
          initialSize = D * 0.6;
          size = initialSize;
          density = p.pixelDensity();
          img.resize(p.width, p.height);
          p.background(32);
          p.noStroke();
          p.textSize(p.width / 37);
          p.rectMode(p.CENTER);
          p.textAlign(p.LEFT, p.CENTER);
          if (p.textWidth(longMessage) >= p.width - 2 * (D / 15)) {
            tWidth = p.width - 2 * (D / 15);
          } else {
            tWidth = p.textWidth(longMessage);
          }
        };

        p.draw = function draw() {
          let k = initialSize / size;
          if (k > 60) {
            k = 60;
          }
          for (let i = 0; i < k; i++) {
            plot();
          }
          if (clicked) {
            p.background(0, transparency);
            if (transparency < 255) {
              transparency += 0.9;
            }
            if (textColor < 254) {
              textColor += 2;
            }

            p.fill(p.color(255, 255, 255, textColor));
            p.text(longMessage, p.width / 2, p.height / 2, tWidth * 1.07);
          }
        };

        p.touchStarted = function touchStarted() {
          if (p.mouseX > 0 && p.mouseY > 0 && p.mouseX < p.width && p.mouseY < p.height) {
            if (!toggleLock) {
              clicked = !clicked;
              if (clicked) {
                transparency = 0;
                size = D / 10;
              }
              if (!clicked) {
                transparency = 0;
                size = D / 10;
                textColor = 0;
              }
            }
          }
          return false;
        };

        function plot() {
          const x = p.floor(myRandom.random_num(0, img.width));
          const y = p.floor(myRandom.random_num(0, img.height));

          const index = 4 * (y * img.width + x);

          const r = img.pixels[index];
          const g = img.pixels[index + 1];
          const b = img.pixels[index + 2];

          const pixelColor = p.color(r, g, b);

          p.fill(pixelColor);
          p.circle(x, y, size);
          if (size > initialSize * 0.3) {
            size = size - size / 10;
          } else if (size > D / 200) {
            size = size - size / 1000;
          }
        }

        p5Instance.current = p;
      } catch (error) {
        console.log('Error sketch art: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (parentElm) {
      const children = parentElm.children;

      if (children.length <= 1) new p5(sketch, parentElm);
    }
    return () => {
      if (p5Instance.current) {
        p5Instance.current.remove();
      }
    };
  }, [parentId, size, text, tokenData, tokenData?.hash, parentElm]);

  useEffect(() => {
    displayText.current = text;
  }, [text]);

  return (
    <Box style={{ width: '270px' }}>
      {isLoading ? (
        <Box p={1}>
          <Loading />
        </Box>
      ) : (
        <Box>
          <div className="art" ref={sketchRef}></div>
          <div
            style={{ backgroundColor: '#fff' }}
            hidden={true}
            onClick={() => {
              handleSetupWithText();
            }}
          >
            Decrypt
          </div>
        </Box>
      )}
    </Box>
  );
};

export default memo(SketchArtSentiments);
