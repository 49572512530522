// @ts-nocheck
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { Box } from '@mui/material';
import useWallet from 'hooks/Wallet/useWallet';
import p5 from 'p5';
import { memo, useEffect, useRef, useState } from 'react';
import { TokenData } from 'types/common';
import { getCoreGenArt } from 'utils/contract/common';
import Loading from './Loading';

const SketchArtSnowFlake = ({
  tokenData,
  text = '',
  parentId,
  size = 'normal',
}: {
  tokenData: TokenData | undefined;
  text?: string;
  parentId: string;
  size?: 'small' | 'normal';
}) => {
  const sketchRef = useRef<HTMLDivElement>(null);
  const p5Instance = useRef<p5 | null>(null);
  const displayText = useRef('');
  const [isLoading, setIsLoading] = useState(false);

  const { provider, chainId } = useWallet();
  const coreContractInfo = getCoreGenArt(chainId);
  const [parentElm, setParentElm] = useState<any>(null);

  const handleSetupWithText = () => {
    if (!text) return;
    if (window.setupWithText) return window?.setupWithText(text || '');
  };

  useEffect(() => {
    const parent = window.document.getElementById(parentId);
    setParentElm(parent);
  }, [window.document.getElementById(parentId)]);

  useEffect(() => {
    const sketch = async (p: p5) => {
      setIsLoading(true);
      try {
        let stepSize; // Length of each step
        let angleStep; // Angle step for hexagonal symmetry
        let path = []; // Array to store the path points
        let lines = []; // Array to store lines with their current index on the path
        let spawnInterval = 50; // Interval after which a new line starts
        let maxPathLength = 800; // Maximum length of the path
        let lineUpdateInterval = 1; // Interval for updating line positions
        let clicked = false;
        let sw;
        let sc = 0;
        let shortMessage = '';
        let longMessage = 'Dear Ben,\n\nHappy Birthday!\n\nLove,\n\nAlex';
        let currentCharacter = 0;
        let currentString = '';
        let D;
        let tHeight = 0;
        let transparency = 10;
        let tWidth;

        let myRandom;
        let textFill = 0;

        let font;
        let toggleLock = true;

        document.addEventListener('touchstart', {});

        let egHash = tokenData.hash;

        const hashPairs = [];
        for (let j = 0; j < 32; j++) {
          hashPairs.push(egHash.slice(2 + j * 2, 4 + j * 2));
        }

        const decPairs = hashPairs.map((x) => {
          return parseInt(x, 16);
        });

        const seed = parseInt(egHash.slice(0, 16), 16);

        font = p.loadFont(
          'https://white-magic-hyena-356.mypinata.cloud/ipfs/QmenNfLw4c1vnWsZDB8LY3b5ZfnfyuTQcqwKZS68XEgBMX'
        );

        window.setupWithText = function (text) {
          toggleLock = false;
          const tmp = text.split('#%zxy@&3');

          shortMessage = p.trim(tmp[0] || '');
          longMessage = p.trim(tmp[1] || '');

          p.setup();
        };

        p.setup = function setup() {
          myRandom = new Random();
          p.textFont(font);

          if (p.windowWidth >= p.windowHeight) {
            D = p.windowHeight;
          } else {
            D = p.windowWidth;
          }
          D=620;
          p.createCanvas(D, D);
          let fSize = p.min(p.width, p.height) * 0.035; 
          fSize += p.max((p.width, p.height) - p.min(p.width, p.height)) / 120;
          p.textSize(fSize);
          angleStep = p.TWO_PI / 6; 
          p.background(255);

          sw = D / 700;
          stepSize = D / 140;
          tHeight = calculateTextHeight(longMessage, fSize);

          if (p.textWidth(longMessage) >= p.width - 2 * (p.width / 30)) {
            tWidth = p.width - 2 * (p.width / 30);
          } else {
            tWidth = p.textWidth(longMessage) * 1.07;
          }
        };

        p.draw = function draw() {
          p.frameRate(57);
          p.textLeading(30);
          p.strokeWeight(sw);
          p.background(255, transparency);

          
          if (path.length < maxPathLength) {
            let stepDirection = p.floor(myRandom.random_num(0, 5) + myRandom.random_num(0, 1));
            let angle = stepDirection * angleStep;
            let lastPoint =
              path.length > 0 ? path[path.length - 1] : p.createVector(p.width / 2, p.height / 2);
            let newX = lastPoint.x + p.cos(angle) * stepSize;
            let newY = lastPoint.y + p.sin(angle) * stepSize;

            if (p.abs(p.dist(newX, newY, p.width / 2, p.height / 2)) < (620 / 2) * 0.94) {
              path.push(p.createVector(newX, newY));
              path.push(p.createVector(newX, newY));
            } else {
              maxPathLength = path.length;
            }
          }


          if (p.frameCount % spawnInterval === 0) {
            lines.push(0); 
          }

          for (let i = 0; i < lines.length; i++) {
            if (lines[i] < path.length - 1) {
              let point1 = path[lines[i]];
              let point2 = path[lines[i] + 1];

              for (let j = 0; j < 6; j++) {
                let rotatedAngle = angleStep * j;
                drawMirroredLine(point1, point2, rotatedAngle);
                drawMirroredLine(point1, point2, rotatedAngle, true);
              }

              
              if (p.frameCount % lineUpdateInterval === 0) {
                lines[i]++; 
              }
            }
          }
          p.stroke(0);
          if (clicked) {
            textFill = 0;
            sc = 255;
            if (transparency < 250) {
              transparency++;
            }
            if (currentCharacter < currentString.length + 2) {
              currentCharacter += 0.5;
            }
          } else {
            sc = 0;
            if (textFill < 255) {
              textFill += 5;
            } else {
              if (transparency > 10) {
                transparency--;
              }

              currentCharacter = 0;
            }
          }
          currentString = longMessage.substring(0, currentCharacter);

          let textYStart = (p.height - tHeight) / 2;

          p.textAlign(p.LEFT);
          p.rectMode(p.CENTER);
          p.noStroke();
          p.fill(textFill);
          p.text(currentString, p.width / 2, textYStart, tWidth);

          p.stroke(sc);
        };

        function drawMirroredLine(point1, point2, angle, mirror = false) {
          let midX = p.width / 2;
          let midY = p.height / 2;

          let p1 = p.createVector(point1.x - midX, point1.y - midY);
          let p2 = p.createVector(point2.x - midX, point2.y - midY);

          if (mirror) {
            p1.y *= -1;
            p2.y *= -1;
          }

          p1 = rotatePoint(p1, angle);
          p2 = rotatePoint(p2, angle);

          p.line(p1.x + midX, p1.y + midY, p2.x + midX, p2.y + midY);
        }

        function rotatePoint(point, angle) {
          return p.createVector(
            point.x * p.cos(angle) - point.y * p.sin(angle),
            point.x * p.sin(angle) + point.y * p.cos(angle)
          );
        }

        p.touchStarted = function touchStarted() {
          if (!toggleLock) {
            if (p.mouseX > 0 && p.mouseY > 0 && p.mouseX < p.width && p.mouseY < p.height) {
              clicked = !clicked;
              if (!clicked) {
                transparency = 60;
              }
            }
          }
          return false;
        };

        function calculateTextHeight(text, fontSize) {
          p.textSize(fontSize);
          let lineHeight = fontSize; 
          let lines = text.split('\n');
          let totalHeight = 0;

          for (let line of lines) {
            let words = line.split(' ');
            let currentLine = '';

            for (let word of words) {
              let testLine = currentLine + word + ' ';
              let metrics = p.textWidth(testLine);

              if (metrics > p.width && currentLine !== '') {
                totalHeight += lineHeight;
                currentLine = word + ' ';
              } else {
                currentLine = testLine;
              }
            }
            totalHeight += lineHeight; 
          }

          return totalHeight * 1.4;
        }
        class Random {
          constructor() {
            this.useA = false;
            let sfc32 = function (uint128Hex) {
              let a = parseInt(uint128Hex.substring(0, 8), 16);
              let b = parseInt(uint128Hex.substring(8, 16), 16);
              let c = parseInt(uint128Hex.substring(16, 24), 16);
              let d = parseInt(uint128Hex.substring(24, 32), 16);
              return function () {
                a |= 0;
                b |= 0;
                c |= 0;
                d |= 0;
                let t = (((a + b) | 0) + d) | 0;
                d = (d + 1) | 0;
                a = b ^ (b >>> 9);
                b = (c + (c << 3)) | 0;
                c = (c << 21) | (c >>> 11);
                c = (c + t) | 0;
                return (t >>> 0) / 4294967296;
              };
            };
            // seed prngA with first half of tokenData.hash
            this.prngA = new sfc32(tokenData.hash.substring(2, 34));
            // seed prngB with second half of tokenData.hash
            this.prngB = new sfc32(tokenData.hash.substring(34, 66));
            for (let i = 0; i < 1e6; i += 2) {
              this.prngA();
              this.prngB();
            }
          }
          // random number between 0 (inclusive) and 1 (exclusive)
          random_dec() {
            this.useA = !this.useA;
            return this.useA ? this.prngA() : this.prngB();
          }
          // random number between a (inclusive) and b (exclusive)
          random_num(a, b) {
            return a + (b - a) * this.random_dec();
          }
          // random integer between a (inclusive) and b (inclusive)
          // requires a < b for proper probability distribution
          random_int(a, b) {
            return Math.floor(this.random_num(a, b + 1));
          }
          // random boolean with p as percent liklihood of true
          random_bool(p) {
            return this.random_dec() < p;
          }
          // random value in an array of items
          random_choice(list) {
            return list[this.random_int(0, list.length - 1)];
          }
        }
        // p.preload=preload()
        // p.setup = setup()
        // p.draw = draw()
        // p.touchStarted = touchStarted()
        p5Instance.current = p;
      } catch (error) {
        console.log('Error sketch art: ', error);
      } finally {
        setIsLoading(false);
      }
    };

    if (parentElm) {
      const children = parentElm.children;

      if (children.length <= 1) new p5(sketch, parentElm);
    }
    return () => {
      if (p5Instance.current) {
        p5Instance.current.remove();
      }
    };
  }, [parentId, size, text, tokenData, tokenData?.hash, parentElm]);

  useEffect(() => {
    displayText.current = text;
  }, [text]);

  handleSetupWithText();

  return (
    <Box style={{ width: '270px' }}>
      {isLoading ? (
        <Box p={1}>
          <Loading />
        </Box>
      ) : (
        <Box>
          <div className="art" ref={sketchRef}></div>
        </Box>
      )}
    </Box>
  );
};

export default memo(SketchArtSnowFlake);
