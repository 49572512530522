/**
 * Arbitrum Sepolia
 */
export const ARBITRUM_CHAIN = '0xa4b1';
export const ARBITRUM_MULTICALL = '0xcA11bde05977b3631167028862bE2a173976CA11';
export const ARBITRUM_GRAPHQL_URL = '';
export const ARBITRUM_INFO = {
  CONTRACT_VERSION: 'V3',
  GEN_ART_721_CORE_V3_ENGINE_LOGIC: '0xd1d1222f6d3e4f64db1c025ecd0b314db8449ac4',
  MINT_CONTRACT_ADDRESS: '0x94560abecb897f359ee1a6ed0e922315da11752d',
  MINTER_SET_PRICE_V5_ADDRESS: '0xe2bC24f74ed326CA4deB75753942731A566ebC83',
  MINTER_SET_PRICE_ERC20_V5_ADDRESS: '0xE89b16B4e5Cb619772894E29650437dee9779933',

  LIGHT_HOUSE_CORE: '0x52dFE68f039B5Ea66114A651e84Fc2D2c59d51f2',

  MEDIA_URL: 'https://plottablesv3-goerli.s3.amazonaws.com',
  TOKEN_URL: 'https://token.staging.artblocks.io',
  GENERATOR_URL: 'https://generator-staging-goerli.artblocks.io',
  EDIT_PROJECT_URL: 'https://artist-staging.artblocks.io/engine/fullyonchain/projects',
};

// =============================================================================
/**
 * Ethereum Sepolia
 */
export const ETHEREUM_SEPOLIA_CHAIN = '0xaa36a7';
export const ETHEREUM_SEPOLIA_MULTICALL = '0xcA11bde05977b3631167028862bE2a173976CA11';
export const ETHEREUM_SEPOLIA_GRAPHQL_URL = '';
export const ETHEREUM_SEPOLIA_INFO = {
  CONTRACT_VERSION: 'V3',
  GEN_ART_721_CORE_V3_ENGINE_LOGIC: '0x2A9Dd2b682Ab03eEdC93346873d36D7771b9082b',
  MINT_CONTRACT_ADDRESS: '0x715Bf392337752E5Ea45200C52eBDa03a3eEf34c',
  MINTER_SET_PRICE_V5_ADDRESS: '0x59b3Ac9889FaD6c0DeA2e268406AD70432d3C396',
  MINTER_SET_PRICE_ERC20_V5_ADDRESS: '0xD3AdC2ea904521a6dDE3a545018718EEFcf5e647',

  LIGHT_HOUSE_CORE: '',

  MEDIA_URL: 'https://plottablesv3-goerli.s3.amazonaws.com',
  TOKEN_URL: 'https://token.staging.artblocks.io',
  GENERATOR_URL: 'https://generator-staging-goerli.artblocks.io',
  EDIT_PROJECT_URL: 'https://artist-staging.artblocks.io/engine/fullyonchain/projects',
};

// =============================================================================
/**
 * Ethereum Goerli
 */
export const ETHEREUM_GOERLI_CHAIN = '0x5';
export const ETHEREUM_GOERLI_GRAPHQL_URL =
  'https://api.studio.thegraph.com/query/61087/test-subgraph-goerli/version/latest';
export const ETHEREUM_INFO = {
  CONTRACT_VERSION: 'V3',
  GEN_ART_721_CORE_V3_ENGINE_LOGIC: '0x45aF9A18239F5b354d1C85aF0e014D1c3013D89A',
  MINT_CONTRACT_ADDRESS: '0xb301fDEDF6f61F0B6838D1Bc5c589778508778d7',
  MINTER_SET_PRICE_V5_ADDRESS: '0x7ceCe0d27730A36953aBd8f64AA1231Dacfa6c9E',
  MINTER_SET_PRICE_ERC20_V5_ADDRESS: '0xd334f0f975B3BA771CB5dAF9643b743188953CF6',

  LIGHT_HOUSE_CORE: '0x50Da6CAd3E0b8C247afd2396EFDd11D403E371ae',

  MEDIA_URL: 'https://plottablesv3-goerli.s3.amazonaws.com',
  TOKEN_URL: 'https://token.staging.artblocks.io',
  GENERATOR_URL: 'https://generator-staging-goerli.artblocks.io',
  EDIT_PROJECT_URL: 'https://api.studio.thegraph.com/query/61087/test-subgraph-goerli/version/latest',
};

/**
 * Arbitrum Goerli
 */
export const ARBITRUM_GOERLI_CHAIN = '0x66eed';
export const ARBITRUM_GOERLI_MULTICALL = '0x108B25170319f38DbED14cA9716C54E5D1FF4623';
export const ARBITRUM_GOERLI_GRAPHQL_URL =
  'https://api.studio.thegraph.com/query/61087/test-subgraph-arbitrum-goerli/version/latest';
export const ARBITRUM_GOERLI_INFO = {
  CONTRACT_VERSION: 'V3',
  GEN_ART_721_CORE_V3_ENGINE_LOGIC: '0x3eD65CE87CB09D23c11A0762e992Cc6F021Ff2c8',
  MINT_CONTRACT_ADDRESS: '0x8BB9e57546CB1c2EB74d0DDC65725e34856A7aE2',
  MINTER_SET_PRICE_V5_ADDRESS: '0xDf2Fd19a3545874071328C9CC34AD5a18cf365C8',
  MINTER_SET_PRICE_ERC20_V5_ADDRESS: '0xa11eb4006ca58599D4d44cc81FDB491cA5f56Ad6',
  LIGHT_HOUSE_CORE: '0x34D65dA49c270616304B1b4c0B4607540dFBFcde',
  GRAPHQL_URL: 'https://api.studio.thegraph.com/query/61087/test-subgraph-arbitrum-goerli/version/latest',

  MEDIA_URL: 'https://plottablesv3-goerli.s3.amazonaws.com',
  TOKEN_URL: 'https://token.staging.artblocks.io',
  GENERATOR_URL: 'https://generator-staging-goerli.artblocks.io',
  EDIT_PROJECT_URL: 'https://artist-staging.artblocks.io/engine/fullyonchain/projects',
};

export const ARBITRUM_SEPOLIA_CHAIN = '0x66eee';
export const ARBITRUM_SEPOLIA_MULTICALL = '0xcA11bde05977b3631167028862bE2a173976CA11';
export const ARBITRUM_SEPOLIA_INFO = {
  CONTRACT_VERSION: 'V3',
  GEN_ART_721_CORE_V3_ENGINE_LOGIC: '0x1E615ee4C7AC89B525d48AeedF01d76E4e06a2d5',
  MINT_CONTRACT_ADDRESS: '0xa07f47c30C262adcC263A4D44595972c50e04db7',
  MINTER_SET_PRICE_V5_ADDRESS: '0x7497909537cE00fDda93c12d5083D8647C593c67',
  MINTER_SET_PRICE_ERC20_V5_ADDRESS: '0x422f493D257e8eFab558137299a509d5a2702BBc',

  LIGHT_HOUSE_CORE: '0xB583adf1444b467e1af2eF0f482782Fc421A2232',

  MEDIA_URL: 'https://plottablesv3-goerli.s3.amazonaws.com',
  TOKEN_URL: 'https://token.staging.artblocks.io',
  GENERATOR_URL: 'https://generator-staging-goerli.artblocks.io',
  EDIT_PROJECT_URL: 'https://artist-staging.artblocks.io/engine/fullyonchain/projects',
};
