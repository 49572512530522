import { Alert, Button, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Box } from '@mui/system';
import Loading from 'components/Loading';
import { ORIGINAL_PROJECT_ID, SENTIMENTS_PROJECT_ID, SNOWFLAKE_PROJECT_ID } from 'config';
import { useFetchArtList } from 'hooks/query/useFetchArtList';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import SnowFlakes from '../../../assets/images/art2.jpg';
import Sentiments from '../../../assets/images/art3.jpg';
import Originals from '../../../assets/images/originals.jpg';

const useStyles = makeStyles((theme: any) => ({
  container: {
    padding: '24px 0 30px 0',
  },
  list: {
    display: 'grid',
    gridTemplateColumns: 'repeat(4, 1fr)',
    gridRowGap: 30,
    gridAutoRows: 'auto',

    [theme.breakpoints.down('lg')]: {
      gridTemplateColumns: 'repeat(3, 1fr)',
    },
  },
}));

function ListNFT() {
  const classes = useStyles();
  const { artList, loading, error, refetch } = useFetchArtList();
  const navigate = useNavigate();

  useEffect(() => {
    refetch();
  }, []);

  if (error) {
    return <Alert severity="error">Error loading tokens</Alert>;
  }

  const handleNavigation = (
    tokenId: string,
    { description, projectId }: { description: string; projectId: number }
  ) => {
    navigate(`/token/${tokenId}`, { state: { description, projectId } });
  };

  const getImgSrc = (prjId: number) => {
    switch (prjId.toString()) {
      case ORIGINAL_PROJECT_ID:
        return Originals;
      case SNOWFLAKE_PROJECT_ID:
        return SnowFlakes;
      case SENTIMENTS_PROJECT_ID:
        return Sentiments;
      default:
        return Originals;
    }
  };

  const calculateTokenId = (prjId: number, tokenId: number) => {
    return tokenId - prjId * 1000000;
  };

  return (
    <Box className={classes.container}>
      <>
        {loading ? (
          <Loading />
        ) : artList?.length > 0 && artList.some((x) => x?.tokenId?.length > 0) ? (
          <Box className={classes.list}>
            {artList.map((project) => {
              return project.tokenId.map((token: string) => {
                return (
                  <Box key={token}>
                    <Button
                      onClick={() => {
                        handleNavigation(token, {
                          description: project.description,
                          projectId: +project.projectId,
                        });
                      }}
                      key={token}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <img
                        style={{ width: '280px', height: '280px' }}
                        src={getImgSrc(+project.projectId)}
                        alt={`${token}`}
                      />
                      <Typography
                        sx={{
                          fontFamily: 'Metropolis',
                          fontSize: '18px',
                          fontWeight: 700,
                          textAlign: 'center',
                          marginTop: '10px',
                          textTransform: 'uppercase',
                        }}
                      >
                        {project.name} #{calculateTokenId(+project.projectId, +token)}
                      </Typography>
                    </Button>
                  </Box>
                );
              });
            })}
          </Box>
        ) : (
          <Box>
            <Typography>No Light House NFT's owned yet.</Typography>
          </Box>
        )}
      </>
    </Box>
  );
}

export default ListNFT;
