import { CONTRACT_INFO } from 'config';

export const getContractConfigByAddress = (contractAddress: string) => {
  return CONTRACT_INFO.find(
    (x) => x.GEN_ART_721_CORE_V3_ENGINE_LOGIC.toLowerCase() === contractAddress.toLowerCase()
  );
};

export const getConfiguredContractAddresses = () => {
  return CONTRACT_INFO.map((x) => x.GEN_ART_721_CORE_V3_ENGINE_LOGIC);
};
