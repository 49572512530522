import React from 'react';
import { Alert, Box, Button, Typography, Container } from '@mui/material';
import Page from 'components/Page';
import Hero from 'components/pages/Home/Hero';
import Header from 'components/Header';
import Introduce from 'components/pages/Home/Introduce';
import IntroduceTwo from 'components/pages/Home/IntroduceTwo';
import WhoAreWe from 'components/pages/Home/WhoAreWe';
import WorkWithUs from 'components/pages/Home/WorkWithUs';
import Footer from 'components/pages/Home/Footer';

function HomePage() {
  return (
    <Box
      sx={{
        backgroundColor: '#000',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Header />
      <Container
        maxWidth="xl"
        sx={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            flex: 1,
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Hero />
        </Box>
        {/* <Container maxWidth="xl">
        <Introduce />
        <IntroduceTwo />
        <WhoAreWe />
      </Container>
      <WorkWithUs /> */}
      </Container>
      <Footer />
    </Box>
  );
}

export default HomePage;
