import { useAccount, useNetwork, useProvider, useSigner } from 'wagmi';

function useWallet() {
  const { address, isConnected, isConnecting } = useAccount();
  const { data: signer, isLoading } = useSigner();
  const { chain } = useNetwork();

  const provider = useProvider({ chainId: chain?.id });
  return {
    connected: isConnected,
    account: address,
    chainId: `0x${chain?.id?.toString(16) || '0'}`,
    provider,
    signer,
    isLoading,
    isConnecting,
  };
}

export default useWallet;
