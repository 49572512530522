import { EXPECTED_CHAIN_ID } from 'config';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNetwork, useSwitchNetwork } from 'wagmi';
import useWallet from './Wallet/useWallet';

const useCheckNetwork = () => {
  const { chain } = useNetwork();
  const { account } = useWallet();
  const [expectedNetworkName, setExpectedNetworkName] = useState<string>('');
  const [count, setCount] = useState(0);
  const { chains, isLoading, switchNetwork } = useSwitchNetwork({
    onSettled(data, error) {
      if (account && chain?.id != EXPECTED_CHAIN_ID) {
        toast(`You are connected to wrong network. Please connect to ${expectedNetworkName}`, {
          type: 'error',
        });
      } else
        toast(`Connect to ${expectedNetworkName}`, {
          type: 'success',
        });
    },
  });
  const getExpectedNetworkName = () => {
    chains.forEach((chain: any) => {
      if (chain.id == EXPECTED_CHAIN_ID) setExpectedNetworkName(chain.name);
    });
  };
  useEffect(() => {
    getExpectedNetworkName();
  }, []);

  const openModal = () => {
    switchNetwork && switchNetwork(EXPECTED_CHAIN_ID);
    setCount((props) => props + 1);
  };
  useEffect(() => {
    if (!isLoading && chain?.id != EXPECTED_CHAIN_ID && switchNetwork && count < 1) {
      openModal();
    }
    return;
  }, [isLoading, chain, switchNetwork]);

  return count;
};

export default useCheckNetwork;
